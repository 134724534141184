import axios from 'axios';
import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const MatchAPI = {
  /**
   * Retrieves upcoming matches
   * @returns {object} An object with 'matches' property - the retrieved matches - and 'success'
   */
  getUpcomingMatches: async () => {
    const res = await axios.post(
      `${apiUrl}/match/upcoming`,
      {},
      {
        headers: Util.generateHeaders(),
      },
    );
    return res.data.data;
  },

  /**
   * Retrieves recently finished matches
   * @returns {object} An object with 'matches' property - the retrieved matches - and 'success'
   */
   getRecentMatches: async () => {
    const res = await axios.post(
      `${apiUrl}/match/results`,
      {}, {
        headers: Util.generateHeaders(),
      },
    );
    return res.data.data;
  },

  /**
   * Retrieves bets from the given user
   * @param {String} userId - user to get the results from
   * @returns {object} An object with 'matches' property - the retrieved matches - and 'success'
   */
  getMatchesUser: async (userId) => {
    const res = await axios.post(
      `${apiUrl}/match/bets/${userId}`,
      {}, {
        headers: Util.generateHeaders(),
      },
    );
    return res.data.data;
  },
};

export default MatchAPI;
