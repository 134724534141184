import React from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { Button, Icon, Menu } from 'semantic-ui-react'

import Constants from "../../constants/constants";

const LogoutButton = ( { context }) => {
  const { logout } = useAuth0();

  const handleLogOut = (event) => {
    event.preventDefault();

    // Save the userId in OAuth
    localStorage.removeItem(Constants.SESSION__USER_TOKEN);
    logout({ returnTo: window.location.origin })
  }

  return context === Constants.NAV__WELCOME_SECTION ?
    (
      <Button
        content="Exit"
        icon='sign-out'
        labelPosition='left'
        onClick={handleLogOut}
      />
    ) :
    (
      <Menu.Item
        key='logout'
        onClick={handleLogOut} 
      >
        <Icon
          name='sign-out'
        />
        EXIT
      </Menu.Item>
    )
};

export default LogoutButton;