import axios from 'axios';
import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const UserAPI = {
  /**
   * Get JWT token of the user
   * @param {object} oauthId - user oauthId user
   * @returns {object} An object with 'result' property - and the JWT token of this session
   */
  getOAuthUser: async (
    oauthId,
  ) => {
      const res = await axios.get(
        `${apiUrl}/user/${oauthId}`,
        {}
      );
    
      return res.data;
  },

  /**
   * Creates a user
   * @param {object} user - new user
   * @param {Array} competitions - competitions indicated as favourite upon sign in
   * @returns {object} An object with 'result' property - and the updated value of the bet
   */
  createUser: async (
    user,
    competitions
  ) => {
      const res = await axios.put(
        `${apiUrl}/user/save`,
        {
          user,
          competitions
        },
        {}
      );
    
      return res.data;
  },

  /**
   * Subscribes a user to the reminder emails
   * @returns {object} An object with 'result' property - and the updated value of the bet
   */
  subscribe: async (
  ) => {
      const res = await axios.post(
        `${apiUrl}/user/subscribe`,
        {},
        {
          headers: Util.generateHeaders(),
        },
      );
    
      return res.data;
  },

  /**
   * Unsubscribes a user to the reminder emails
   * @param {object} userId - id of the user
   * @returns {object} An object with 'result' property - and the updated value of the bet
   */
  unsubscribe: async (
    userId,
  ) => {
      const res = await axios.get(
        `${apiUrl}/user/unsubscribe/${userId}`,
        {}
      );
    
      return res.data;
  },

  /**
   * Get basic information of the user
   * @param {object} userId - user id
   * @returns {object} An object with 'result' property - and the updated value of the bet
   */
  getUserInfo: async (
    userId,
  ) => {
      const res = await axios.get(
        `${apiUrl}/user/info/${userId}`,
        {
          headers: Util.generateHeaders(),
        },
      );
    
      return res.data;
  },

  /**
   * Get H2H information to show in the user's profile
   * @param {String} versusId - id of the user to compare to
   * @returns {object} An object with 'result' property - and the updated value of the bet
   */
  getH2H: async (
    versusId
  ) => {
      const res = await axios.post(
        `${apiUrl}/user/versus/${versusId}`,
        {},
        {
          headers: Util.generateHeaders(),
        },
      );
    
      return res.data;
  },

  /**
   * Checks if the user is subscribed to the newsletter or not
   * @returns {object} true or false
   */
  isSubscribed: async (
    ) => {
        const res = await axios.post(
          `${apiUrl}/user/isSubscribed`,
          {},
          {
            headers: Util.generateHeaders(),
          },
        );
      
        return res.data.data;
    },
};

export default UserAPI;
