import { useEffect, useState } from "react";
import {
  Header, Segment, Icon, Dropdown,
} from 'semantic-ui-react'

// API
import AdminAPI from "../api/admin";

// Components
import Loading from "../components/Main/Loading";
import AdminMatch from "../components/Main/Admin/AdminMatch";
import AdminPodiumMatch from "../components/Main/Admin/AdminPodiumMatch";
import Util from "../util";
import Constants from "../constants/constants";


const Admin = () => {
  const [matches, setMatches] = useState([]);
  const [orderedMatches, setOrderedMatches] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(0);
  const [competitionsAvailable, setCompetitionsAvailable] = useState([]);
  const [matchStatusFilter, setMatchStatusFilter] = useState(true);
  const [competitionFilter, setCompetitionFilter] = useState(false);

  const matchStatusFilterOptions = [
    {
      key: 'All',
      text: 'all matches',
      value: false
    },
    {
      key: 'Pending',
      text: 'pending matches',
      value: true
    },
  ];

  const allCompetitionsOption = {
    key: 'All',
    text: 'all competitions',
    value: false
  };

  useEffect(() => {
    async function getMatches() {
      try {
        const loadMatches = await AdminAPI.getRecentMatches();
        setMatches(loadMatches);
      } catch (error) {
        Util.handleError(error);
      }
    }

    try {
      // Reset loader
      setLoading(true);
      setFirstLoad(0);

      // Get matches
      getMatches();
    } catch(error) {
      Util.handleError(error, 'Error getting recent matches. Please retry');
    }
  }, []);

  useEffect(() => {
    if (firstLoad) {
      setLoading(false); // Skip the UseState load

      // Get different competitions available
      if (matches.length) {
        // Get the matches in local time and order them by that
        const localTimeMatches = matches.map((match) => ({...match, localTime: (new Date(match.time))}));
        setOrderedMatches(localTimeMatches.sort((a, b) => new Date(b.localTime) - new Date(a.localTime)));

        // Get unique competitions for the filters
        const uniqueCompetitions = matches.reduce((uniqueCompetitions, match) => {
          const competition = match.competition;
          if (!uniqueCompetitions.some(comp => comp.key === competition._id)) {
            uniqueCompetitions.push(
              {
                key: competition._id,
                text: competition.name,
                value: competition._id,
                image: {
                  src: `./img/competitions/${competition.crestDarkURI}.png`,
                  size:'mini',
                  spaced: 'right',
                  className: 'competition-filter-logo'
                },
              }
            );
          }
          return uniqueCompetitions;
        }, []);

        // Adding default option
        setCompetitionsAvailable([
          allCompetitionsOption,
          ...uniqueCompetitions
        ]);

      } else {
        setCompetitionsAvailable([ allCompetitionsOption ]);
      }
      
    } else {
      setFirstLoad(1);
    }
  }, [matches]);

  const filters = () => {
    return (
        <>
          <Segment
            basic
            className='no-padding'
            style={{lineHeight: 1.6}}
          >
            <Icon name='filter' />
              Showing {' '}
              <Dropdown
                inline
                options={matchStatusFilterOptions}
                defaultValue={false}
                value={matchStatusFilter}
                onChange={(event, target) => setMatchStatusFilter(target.value)}
              />
              {' '} from {' '}
              <Dropdown
                inline
                options={competitionsAvailable}
                defaultValue={false}
                value={competitionFilter}
                onChange={(event, target) => setCompetitionFilter(target.value)}
              />
           <br /><br />            
          </Segment>
        </>
      );
  }

  return isLoading ?
    (
      <Loading/>
    ) :
    (
      <>
        <div className="main ui container">
          <Segment basic>
            <Header>Admin Zone</Header>
            <p>
              From this page you can change the result of the games considered by Ballkie
            </p>
            <p>
              A great power comes with a great responsability. Be careful!
            </p>
          </Segment>
          {filters()}
          { orderedMatches && orderedMatches.length ?
              orderedMatches.map((match) => (
                match?.type === Constants.MATCH_TYPE__1X2 ?
                  (
                    <AdminMatch
                      match={match}
                      filterFinished={matchStatusFilter}
                      filterCompetition={competitionFilter}
                    />
                  ):
                  match?.type === Constants.MATCH_TYPE__PODIUM ?
                    (
                      <AdminPodiumMatch
                        match={match}
                        filterFinished={matchStatusFilter}
                        filterCompetition={competitionFilter}
                      />
                    ):
                    (<></>)
              )) :
              (
                <p>
                  No matches available
                </p>
              )

          }
        </div>
      </>
    )
    

};

export default Admin;
