import { Navigate, useLocation } from "react-router-dom";
import Constants from "../../constants/constants";

const PrivateRoute = ({ children }) => {

    const isAuth = () => localStorage.getItem(Constants.SESSION__USER_TOKEN);
    const location = useLocation();
    
    return isAuth() ?
        children :
        <Navigate to={`/?redirect=${encodeURIComponent(location.pathname + location.search)}`} />
}

export default PrivateRoute;