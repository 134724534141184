import axios from 'axios';
import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const BetAPI = {
  /**
   * Saves the bet of the user
   * @param {string} match - match of the bet
   * @param {string} bet - value of the bet
   * @returns {object} An object with 'result' property - and the updated value of the bet
   */
   upsertBet: async (
    match,
    bet
  ) => {
    const res = await axios.put(
      `${apiUrl}/bet/${match._id}`,
      {
        bet
      },
      {
        headers: Util.generateHeaders(),
      }
    );
  
    return res.data;
  },

  /**
   * Saves the podium bet of the user
   * @param {string} match - match of the bet
   * @param {Object} bet - object bet with { firstPlace, secondPlace, thirdPlace }
   * @returns {object} An object with 'result' property - and the updated value of the bet
   */
  upsertPodiumBet: async (
    match,
    bet
  ) => {
    const res = await axios.put(
      `${apiUrl}/bet/podium/${match._id}`,
      {
        bet
      },
      {
        headers: Util.generateHeaders(),
      }
    );
  
    return res.data;
  },
};

export default BetAPI;
