import axios from 'axios';
import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const LeagueAPI = {
  /**
   * Retrieves upcoming matches
   * @returns {object} An object with 'leagues' property - the retrieved leagues - and 'success'
   */
  getUserLeagues: async () => {
    const res = await axios.post(
      `${apiUrl}/league/leagues`,
      {},
      {
        headers: Util.generateHeaders(),
      },
    );
    return res.data.data;
  },

  /**
   * Retrieves available competitions
   * @returns {object} An object with 'leagues' property - the retrieved leagues - and 'success'
   */
  getCompetitions: async () => {
    const res = await axios.get(
      `${apiUrl}/league/competitions`,
      {},
    );
    return res.data.data;
  },

  /**
   * Creates a league
   * @param {object} league - league to be created
   * @param {boolean} isLeadForm - determines if the league is being created from the lead form
   * @returns {object} An object with 'result' property - and the updated value of the bet
   */
  createLeague: async (
    league,
    isLeadForm
  ) => {
    if (!isLeadForm) {
      const res = await axios.put(
        `${apiUrl}/league/create`,
        {
          league
        },
        {
          headers: Util.generateHeaders(),
        },
      );
    
      return res.data;
    } else {
      const res = await axios.put(
        `${apiUrl}/league/create/lead`,
        {
          league
        },
        {},
      );

      return res.data;
    }
  },

  /**
   * Search leagues per name
   * @param {String} search - Search string
   * @returns {object} A list of the leagues that fulfill the search
   */
  searchLeagues: async (search) => {
    const res = await axios.post(
      `${apiUrl}/league/search`,
      {
        search,
      },
      {
        headers: Util.generateHeaders(),
      },
    );
    return res.data.data;
  },

  /**
   * Adds the user to the league
   * @param {string} leagueId - league to join
   * @param {string} leaguePassword - password, if it's a private league
  * @returns {object} An object with 'result' property and a data.message if something went wrong
   */
  joinLeague: async (
    leagueId,
    leaguePassword
  ) => {
    const res = await axios.put(
      `${apiUrl}/league/join/${leagueId}`,
      {
        leaguePassword
      },
      {
        headers: Util.generateHeaders(),
      },
    );
  
    return res.data;
  },

  /**
   * Accepts the invitation to join a league
   * @param {string} joinToken - token containing league info
  * @returns {object} An object with 'result' property and a data.message if something went wrong
   */
  acceptInviteLeague: async (
    joinToken
  ) => {
    const res = await axios.put(
      `${apiUrl}/league/invite/${joinToken}`,
      {},
      {
        headers: Util.generateHeaders(),
      },
    );
  
    return res.data;
  },

  /**
   * Get the league details
   * @param {String} leagueId - league to display
   * @returns {object} A list of the leagues that fulfill the search
   */
  getLeagueInfo: async (leagueId) => {
    const res = await axios.post(
      `${apiUrl}/league/${leagueId}`,
      {},
      {
        headers: Util.generateHeaders(),
      },
    );

    return res.data;
  },

  /**
   * Removes the user from the league
   * @param {string} leagueId - league to leave
  * @returns {object} An object with 'result' property and a data.message if something went wrong
   */
  leaveLeague: async (
    leagueId
  ) => {
    const res = await axios.put(
      `${apiUrl}/league/leave/${leagueId}`,
      {},
      {
        headers: Util.generateHeaders(),
      },
    );
  
    return res.data;
  },

  /**
   * Get the weekly ranking of the league
   * @param {String} leagueId - league to display
   * @returns {object} Ranking object of the previous week
   */
  getPreviousWeekRanking: async (leagueId) => {
    const res = await axios.post(
      `${apiUrl}/league/weekly/${leagueId}`,
      {},
      {
        headers: Util.generateHeaders(),
      },
    );

    return res.data.data;
  },
};

export default LeagueAPI;
