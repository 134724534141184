import { useState } from "react";

import Constants from "../../../constants/constants";
import ErrorMessages from "../../../constants/errorMessages";

import {
    Button, Modal, Form, Input, Dropdown, Radio, Label,
    Icon, Popup, Segment, Divider, Header, Dimmer, Image, Loader
} from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react';


import CompetitionSelector from "../Aux/competitionSelector";
import LeagueAPI from "../../../api/league";
import Util from "../../../util";
import moment from 'moment';
import Swal from 'sweetalert2';

const CreateLeagueModal = ({ reloadLeagues, label, context }) => {
    const [isOpen, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});

    const [competitions, setCompetitions] = useState([]);
    const [leagueName, setLeagueName] = useState('');
    const [leagueType, setLeagueType] = useState(Constants.LEAGUE_TYPE__PRIVATE);
    const [leaguePassword, setLeaguePassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [leagueStartDate, setLeagueStartDate] = useState('');
    const [leagueTarget, setLeagueTarget] = useState('');
    const [leagueCompetitions, setLeagueCompetitions] = useState([]);
    const [competitionActiveIndex, setCompetitionActiveIndex] = useState([]);
    const [leadEmail, setLeadEmail] = useState('');


    const resetFormErrors = () => {
        setError({});
    }

    const isLeadForm = () => {
        return context === Constants.NAV__CORPORATE_SECTION
    }

    const handleOpen = async () => {
        try {
            setOpen(true);
            setLoading(true);

            resetFormErrors();

            setLeagueName('');
            setLeagueType(Constants.LEAGUE_TYPE__PRIVATE);
            setLeaguePassword('');
            setLeagueTarget('');
            setLeagueStartDate(moment().format('DD MMM YYYY'));
            setLeagueCompetitions([]);
            setLeadEmail('');

            if (!competitions.length) {
                const loadCompetitions = await LeagueAPI.getCompetitions();
                setCompetitions(loadCompetitions);
            }

            setLoading(false);
        } catch(error) {        
            // Stop Loading
            setLoading(false);

            // Fire error
            Util.handleError(error, 'Failed to load competitions. Please reload');
        }
    }

    const handleLeagueCreation = async (event) => {
        event.preventDefault();
        setLoading(true);

        let auxError = {};

        ///// Validation of League Name
        const nameAllowedCharacters = Constants.LEAGUE__NAME_ALLOWED_CHARS;
        const passwordAllowedCharacters = Constants.LEAGUE__PSW_ALLOWED_CHARS;
        let validCompetitions = [];

        if (leagueName === '') {
            auxError.leagueName = ErrorMessages.ERR_MSG__CREATE_LEAGUE__NAME_EMPTY;
        } else if (
            leagueName.length < Constants.LEAGUE__MIN_LEAGUE_NAME ||
            leagueName.length > Constants.LEAGUE__MAX_LEAGUE_NAME
        ) {
            auxError.leagueName = ErrorMessages.ERR_MSG__CREATE_LEAGUE__NAME_INVALID_LENGTH;
        } else if (!nameAllowedCharacters.test(leagueName)) {
            auxError.leagueName = ErrorMessages.ERR_MSG__CREATE_LEAGUE__NAME_INVALID_CHARS;
        }

        ///// Validation of Lead Email
        if (isLeadForm() && leadEmail === '') {
            auxError.leadEmail = ErrorMessages.ERR_MSG__CREATE_LEAGUE__LEAD_EMAIL_EMPTY;
        }

        ///// Validation of Password
        // Password is present if league is private
        if (leagueType === Constants.LEAGUE_TYPE__PRIVATE && !isLeadForm()) {
            if (leaguePassword === '') {
                auxError.leaguePassword = ErrorMessages.ERR_MSG__CREATE_LEAGUE__PSW_EMPTY;
            } else if (!passwordAllowedCharacters.test(leaguePassword)) {
                auxError.leaguePassword = ErrorMessages.ERR_MSG__CREATE_LEAGUE__PSW_INVALID_CHARS;
            }
        }

        ///// Validation of Competitions
        if (leagueCompetitions.length === 0 || leagueCompetitions > Constants.LEAGUE__MAX_COMPETITIONS) {
            auxError.leagueCompetitions = ErrorMessages.ERR_MSG__CREATE_LEAGUE__COMPETITIONS_INVALID_SIZE;
        }

        if (leagueStartDate === '' && !isLeadForm()) {
            auxError.leagueStartDate = ErrorMessages.ERR_MSG__CREATE_LEAGUE__START_DATE_EMPTY;
        } else {
            // Get now
            const startDate = new Date(leagueStartDate);
            const today = new Date(moment());

            // Compare
            const daysFromToday = moment(leagueStartDate).diff(today, "days", false);

            // If the start date is smaller than today, we throw
            if(daysFromToday < 0) {
                auxError.leagueStartDate = ErrorMessages.ERR_MSG__CREATE_LEAGUE__START_DATE_PAST;
            } else if (leagueCompetitions.length) {
                // See if with the current settings they can play the competitions
                const selectedCompetitions = competitions.filter(comp => leagueCompetitions.includes(comp._id));

                validCompetitions = selectedCompetitions.filter(comp => new Date(comp.endDate) > startDate);
                
                if (!validCompetitions.length) {
                    auxError.leagueStartDate = ErrorMessages.ERR_MSG__CREATE_LEAGUE__START_DATE_AFTER_COMPETITIONS;

                }
            }
        }

        if (!Object.keys(auxError).length) {
            // If there are no messages in the errorObj, we proceed sending the request
            try {
                const response = await LeagueAPI.createLeague(
                    {
                        name: leagueName,
                        competitions: leagueCompetitions,
                        type: leagueType,
                        password: Util.hashString(leaguePassword),
                        target: leagueTarget,
                        startDate: moment(leagueStartDate),
                        leadEmail: isLeadForm() ? leadEmail : undefined
                    },
                    isLeadForm()
                );

                // Check if the league was saved or not
                if (response.success) {
                    // Close Pop up
                    setLoading(false);
                    setOpen(false);

                    // Show success message
                    
                    Swal.fire({
                        title: 'League <b>' + leagueName + '</b> created!',
                        text: isLeadForm() ?
                            "We'll reach out in the next working day and help you finalize the configuration of your league" :
                            'Share the credentials with your friends or the link below to let them join',
                        icon: 'success',
                        width: '20em',
                        showConfirmButton: !isLeadForm(), // In lead form, we don't share the join code just now
                        confirmButtonText: 'Copy League URL',
                        confirmButtonColor: '#04476E',
                      }).then(async (result) => {
                        // If the user confirms, we save in the Database
                        if (result.isConfirmed) {
                            navigator.clipboard.writeText(
                                window.location.hostname
                                + Constants.NAV__JOIN_LEAGUE_URI
                                + response.data.league.shareCode
                              );

                            Util.successMessage('Link copied', null);
                        }
                      })
                    
                    // Reload Leagues
                    if (!isLeadForm()) {
                        await reloadLeagues();
                    }
                } else {
                    // If we receive an array with error, it's a controlled rejection
                    auxError = { ...response.data.error}
                }
            } catch(error) {
                // Fire error
                Util.handleError(error, 'Failed to create the league. Please retry');
            }
        }

        setLoading(false);
        setError(auxError);
        
    }

    return (
        <Modal
            as={Form}
            id="create-league"
            error={Object.keys(error).length > 0}
            onClose={() => setOpen(false)}
            onOpen={() => handleOpen()}
            onSubmit={handleLeagueCreation}
            open={isOpen}
            size='small'
            trigger={
                <Button
                    fluid
                    size={isLeadForm() ? 'large' : undefined}
                    color="green"
                    icon
                    labelPosition='left'
                    style={{
                        marginBottom: '0.5em',
                        maxWidth: isLeadForm() ? '250px' : undefined
                    }}
                >
                    <Icon name='circle plus' />
                    {label ? label : 'Create New League'}
                </Button>
            }
        >
            <Modal.Header>
                Create a new League
            </Modal.Header>
            <Modal.Content scrolling>
                <Modal.Description>
                    {loading ?
                        (
                            <Segment>
                                <Dimmer active>
                                <Loader />
                                </Dimmer>
                        
                                <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                            </Segment>
                        ) :
                        (
                            <Segment basic>
                                <p>
                                    🏆 Ready to set up a new league and add some friendly competition to your games? 
                                </p>
                                <p>
                                    We just need to ask you a few quick questions to get everything organized!
                                </p>
                                <Divider clearing />
                                <Header>League Info</Header>
                                <Form.Field
                                    required
                                    error={!!error.leagueName}
                                >
                                    <label>{ isLeadForm() ? 'Company/Group Name' : 'Name'}</label>
                                    <Input
                                        maxLength={Constants.LEAGUE__MAX_LEAGUE_NAME} 
                                        type="text"
                                        placeholder={ isLeadForm() ? 'Name of your company' : 'Name of your league'}
                                        value={leagueName}
                                        onChange={event => setLeagueName(event.target.value)}
                                    >
                                    </Input>
                                    {error.leagueName &&
                                        <Label pointing prompt>{error.leagueName}</Label>}
                                </Form.Field>
                                { !isLeadForm() ?
                                    (
                                        <>
                                            <Form.Group inline>
                                                <label>Type of League</label>
                                                <Form.Field
                                                    control={Radio}
                                                    label='Private'
                                                    value={Constants.LEAGUE_TYPE__PRIVATE}
                                                    checked={leagueType === Constants.LEAGUE_TYPE__PRIVATE}
                                                    onChange={event => setLeagueType(Constants.LEAGUE_TYPE__PRIVATE)}
                                                />
                                                <Form.Field
                                                    control={Radio}
                                                    label='Anyone can join'
                                                    value={Constants.LEAGUE_TYPE__PUBLIC}
                                                    checked={leagueType === Constants.LEAGUE_TYPE__PUBLIC}
                                                    onChange={event => setLeagueType(Constants.LEAGUE_TYPE__PUBLIC)}
                                                />
                                            </Form.Group>
                                            <Form.Field
                                                required
                                                error={!!error.leaguePassword}
                                            >
                                                <label>
                                                    Password
                                                    <Popup
                                                        content='Make sure to save the password; you need it to invite your friends!'
                                                        position="right center"
                                                        trigger={
                                                            <Icon
                                                                name="question circle"
                                                                color="grey"
                                                            />
                                                        }
                                                    />
                                                </label>
                                                <Input
                                                    disabled={leagueType === Constants.LEAGUE_TYPE__PUBLIC}
                                                    placeholder='Required to join the league'
                                                    icon={
                                                        <Icon
                                                            name={showPassword ? "eye slash" : "eye"}
                                                            link
                                                            onClick={() => setShowPassword(!showPassword)}
                                                        />
                                                    }
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={leaguePassword}
                                                    onChange={event => setLeaguePassword(event.target.value)}
                                                />
                                                {error.leaguePassword &&
                                                    <Label pointing prompt>{error.leaguePassword}</Label>}
                                            </Form.Field>
                                            <Form.Field
                                                required
                                                error={!!error.leagueStartDate}
                                            >
                                                <label>Start Date of the League:</label>
                                                <DateInput
                                                    dateFormat="DD MMM YYYY"
                                                    placeholder="Select a date"
                                                    value={leagueStartDate}
                                                    minDate={new Date()}
                                                    closable
                                                    onChange={(event, data) => setLeagueStartDate(data.value)}
                                                />
                                                {error.leagueStartDate &&
                                                <Label pointing prompt>{error.leagueStartDate}</Label>}
                                            </Form.Field>
                                            <Form.Field>
                                                <label>Who are you playing with?</label>
                                                <Dropdown
                                                    fluid
                                                    value={ leagueTarget}
                                                    onChange={ (event, data) => setLeagueTarget(data.value)}
                                                    placeholder='This league is for...'
                                                    options={Constants.LEAGUE__TARGET}
                                                />
                                            </Form.Field>
                                        </>
                                    ) :
                                    (
                                        <>
                                            <Form.Field
                                                required
                                                error={!!error.leadEmail}
                                            >
                                                <label>Your Email</label>
                                                <Input
                                                    type="email"
                                                    placeholder='Your email address'
                                                    value={leadEmail}
                                                    onChange={event => setLeadEmail(event.target.value)}
                                                />
                                                {error.leadEmail &&
                                                    <Label pointing prompt>{error.leadEmail}</Label>}
                                            </Form.Field>
                                        </>
                                    )
                                }
                                <Divider clearing />
                                <Header
                                    color={error.leagueCompetitions ? 'red' : null}
                                >
                                    Competitions</Header>
                                <Segment basic fluid className='no-padding'>
                                    <p>
                                        You can select a maximum of {Constants.LEAGUE__MAX_COMPETITIONS} competitions per league.<br />
                                        Missing a competition? Let us know <a href='https://forms.gle/U6ivhygYmgQyupkM7'>here</a></p>
                                </Segment>
                                {error.leagueCompetitions &&
                                    <Label pointing='below' basic color='red'>{error.leagueCompetitions}</Label>}
                                <CompetitionSelector
                                    competitions={competitions}
                                    selectedCompetitions={leagueCompetitions}
                                    setLeagueCompetitions={setLeagueCompetitions}
                                    activeIndex={competitionActiveIndex}
                                    setActiveIndex={setCompetitionActiveIndex}
                                    maxCompetitions={Constants.LEAGUE__MAX_COMPETITIONS}
                                />
                            </Segment>
                        )}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    content="Create"
                    type="submit"
                    form="create-league"
                    positive
                />
            </Modal.Actions>
        </Modal>
    );
  };
  
  export default CreateLeagueModal;