import { useState } from "react";
import { useScreenshot } from 'use-react-screenshot';
import {
  Menu, Icon, Popup, Loader
} from 'semantic-ui-react';

import Util from "../../../util";
import Constants from "../../../constants/constants";

const MobileShare = (
  { sourceRef, className }
) => {
  const [image, takeScreenshot] = useScreenshot(
    {
      type: "image/png",
      quality: 1.0
    }
  );
  const [loading, setLoading] = useState(false);

  function dataURItoBlob(data) {
    const byteString = atob(data.split(',')[1]);
    const mimeString = data.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  /**
   * Handler called when you want to generate and share the link of a league
   * @returns {void}
   */
  const handleShareClick = async () => {
    await setLoading(true);

    const screenshot = await takeScreenshot(sourceRef.current);
    
    const blob = dataURItoBlob(screenshot);

    const imageFile = new File(
      [blob],
      'ranking.png',
      {
        type: 'image/png',
        lastModified: new Date().getTime()
      }
    );

    if (navigator.share) {
      navigator.share({
        files: [ imageFile ],
      })
      .catch((error) => {
        if (error.code !== Constants.SHARE__ABORT_ERROR_CODE) {
          // Fire error
          Util.handleError(error, 'Failed to share. Please retry');
        }
      });
    }

    setLoading(false);
  };

  return navigator.share ?
    (
      <Popup
        trigger={
          <Menu.Item
            onClick={handleShareClick}
          >
            { loading ?
              (
                <Loader active inline size='tiny' />
              ) :
              (
                <Icon
                  name='share square'
                  className={className}
                  color='black'
                />
              )
            }
          </Menu.Item>
        }
        on='hover'
        content='Brag on social media'
        size='mini'
        position='left center'
      />
    ) :
    (<></>)
    
  };
  
  export default MobileShare;
  