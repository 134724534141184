import React from 'react';

import "../../styles/matchstatus.css";

import {
  Segment, Grid, Image, Icon, Header
} from 'semantic-ui-react'

const BetDistribution = () =>
{
  const APPLE_STORE__URL = 'https://apps.apple.com/app/apple-store/id6477320391?pt=122577278&ct=web_redirect&mt=8';
  const ANDROID_STORE__URL = 'https://play.google.com/store/apps/details?id=com.ballkie';

  const handleAppStoreClick = () => {
    window.location.href=APPLE_STORE__URL;
  }

  const handleGooglePlayClick = () => {
    window.location.href=ANDROID_STORE__URL;
  }

  return (
    <Segment
      basic
      style={{ paddingLeft: '10px', paddingRight: '10px' }}
    >
      <Grid
        centered
        stackable
        columns={2}
        className="content-column"
        verticalAlign="middle"
      >
        <Grid.Column largeScreen={8}>
          <Image src='./img/logo/app-overview.png' />
        </Grid.Column>
        <Grid.Column largeScreen={6} textAlign="center">
          <Header size="huge" className="content-header">
            An App to fall in love with
          </Header>
          <Grid
            stackable
            centered
            columns={2}
            verticalAlign='middle'
          >
          <Grid.Column>
            <Segment basic textAlign="center" className="no-bottom-padding">
              <Header size="big" style={{fontWeight: 'normal'}} icon>
                <Icon name='gamepad' style={{ fontSize: '35px' }} />
                Pick-em style prediction gameplay
              </Header>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment basic textAlign="center" className="no-bottom-padding">
            <Header size="big" style={{fontWeight: 'normal'}} icon>
              <Icon name='trophy' style={{ fontSize: '35px' }} />
              Build your digital trophy cabinet
            </Header>
            </Segment>
          </Grid.Column>
          </Grid>
          <Grid columns={2} centered>
            <Grid.Column>
              <Segment onClick={handleAppStoreClick} basic className='no-padding no-margin' textAlign={'right'}>
                <Image src='./img/logo/download-apple-store.png' height={50} style={{ display:'inline'}} />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment onClick={handleGooglePlayClick} basic className='no-padding no-margin' textAlign={'left'}>
                <Image src='./img/logo/download-google-play.png' height={50} style={{ display:'inline'}} />
              </Segment>
            </Grid.Column>
          </Grid>
          <p>
            <Header as='h3' style={{ paddingTop: '10px', fontWeight: 'normal'}}>
              Supported in 🇬🇧🇪🇸
            </Header>
          </p>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default BetDistribution;