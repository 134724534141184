  // Components
  import { Container, Header, Segment, Menu, Divider, Image, Grid, Icon, Button, Statistic } from 'semantic-ui-react'
  import { Link } from 'react-scroll';
  import Constants from "../constants/constants";
  import CreateLeagueModal from '../components/Main/Aux/createLeagueModal';
  import AppDisplay from '../components/Showcase/AppDisplay';

  import "../styles/main.css";

  const Corporate = () => {
    return (
      <>
        <Segment
          textAlign='center'
          style={{
            padding: '1em 0em',
            backgroundImage: 'url(./img/logo/maradona-bg.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            boxShadow: 'inset 0 0 0 1000px rgba(4,71,110,.7)',
            minHeight: '300px'
            }}
          vertical
        >
            <Menu
              size='large'
              pointing
              fluid
              className='no-margin no-border'
              style={{
                position:'fixed',
                top: 0,
                width: '100%',
                zIndex: '1000',
                background: 'rgba(0,0,0,.8)',
                paddingLeft: '1rem',
                paddingRight: '1rem'
              }}
            >
              <Menu.Item>
                  <Image 
                    src='./img/logo/logo_blue.png'
                    height={70}
                    verticalAlign='middle'
                  />
                  <span className='reduced-padding'>
                    <Header as='h1' inverted>Ballkie</Header>
                  </span>
                </Menu.Item>
                <Menu.Menu position='right'>
                  <Menu.Item>
                    <Button
                      content='Get Started' inverted
                      as={Link} to="easy-as-123" smooth={true} duration={500} offset={-100}>
                    </Button>
                  </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Container textAlign="center">
            <Divider hidden/>
            <Segment basic textAlign="left" style={{ paddingTop: '85px'}}>
              <Grid
                verticalAlign='middle'
                centered
                stackable
                columns={2}
                style={{ width: '100%' }}
              >
                <Grid.Column largeScreen={9} style={{ paddingRight: '30px' }}>
                  <Header
                    as='h1'
                    content='Play Easy, Bond Hard'
                    inverted
                  />
                  <Header
                    as='h3'
                    content='Ballkie transforms team-building into an exciting, season-long challenge with sports predictions, fostering camaraderie and boosting morale - ideal for remote teams!'
                    inverted
                    style={{
                      fontWeight: 'normal',
                    }}
                  />
                </Grid.Column>
                <Grid.Column largeScreen={5} textAlign="center" className="no-padding">
                  <Segment basic fluid className="no-padding" textAlign="right">
                    <Button
                      fluid content='Get Started' className="secondary-background reduced-margin" style={{maxWidth: '350px'}}
                      as={Link} to="easy-as-123" smooth={true} duration={500} offset={-100}
                    />
                    <Button
                      fluid content='Contact Us' className="reduced-margin" inverted style={{maxWidth: '350px'}}
                      href="mailto:ballkie.team@gmail.com?subject=Question%20about%20Ballkie"
                    />
                  </Segment>
                </Grid.Column>
              </Grid>
            </Segment>
            <Divider hidden />
            <Divider hidden />
            <Grid
              container
              verticalAlign='middle'
              centered
              stackable
              columns={3}
            >
              <Grid.Column className="no-padding">
                <Segment basic textAlign="center" className="no-bottom-padding">
                  <Header icon inverted>
                    <Icon name='gift' />
                    Gamify your perks
                  </Header>
                  <Header
                    as='h4'
                    content='Enjoy exclusive rewards for all players, host your charity actions, and organize giveaways for the winners'
                    inverted
                    className='reduced-margin'
                    style={{
                      fontWeight: 'normal',
                    }}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column className="no-padding">
                <Segment basic textAlign="center" className="no-bottom-padding">
                  <Header icon inverted>
                    <Icon name='paint brush' />
                    Customized Experience
                  </Header>
                  <Header
                    as='h4'
                    content="Tailor the platform's look and feel to reflect your company’s brand."
                    inverted
                    className='reduced-margin'
                    style={{
                      fontWeight: 'normal',
                    }}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column className="no-padding">
                <Segment basic textAlign="center" className="no-bottom-padding">
                  <Header icon inverted >
                    <Icon name='mobile alternate' />
                    Dedicated Platform
                  </Header>
                  <Header
                    as='h4'
                    content='We offer a native iOS and Android app, and a web version with a set of unique features'
                    inverted
                    className='reduced-margin'
                    style={{
                      fontWeight: 'normal',
                    }}
                  />
                </Segment>
              </Grid.Column>
            </Grid>
            <Divider hidden />
          </Container>
        </Segment>
        <Divider hidden />
        <AppDisplay />
        <Divider />
        <Segment
          basic
          style={{ paddingLeft: '10px', paddingRight: '10px'}}
        >
          <Grid
            centered
            stackable
            columns={2}
            className="content-column"
            verticalAlign="middle"
          >
            <Grid.Column largeScreen={8} textAlign="center">
              <Header size="huge" className="content-header">
                Made for everyone
              </Header>
              <Grid
                stackable
                centered
                columns={2}
                verticalAlign='middle'
              >
                <Grid.Column>
                  <Segment basic textAlign="center" className="no-bottom-padding">
                    <Header size="big" style={{fontWeight: 'normal'}} icon>
                      <Icon name='balance' style={{ fontSize: '35px' }} />
                      Check the trends of the community in each match to help you pick wisely
                    </Header>
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment basic textAlign="center" className="no-bottom-padding">
                    <Header size="big" style={{fontWeight: 'normal'}} icon>
                      <Icon name='coffee' style={{ fontSize: '35px' }} />
                      Save your picks on what a coffee break lasts for
                    </Header>
                  </Segment>
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column largeScreen={6} textAlign='center'>
                <Image size="large" src='./img/logo/picking.gif' />
            </Grid.Column>
            </Grid>
        </Segment>
        <Divider />
        <Segment
          basic
          style={{ paddingLeft: '10px', paddingRight: '10px' }}
        >
          <Grid
            centered
            stackable
            columns={2}
            className="content-column"
            verticalAlign="middle"
          >
            <Grid.Column largeScreen={8} textAlign='center'>
            <Segment basic style={{ textAlign: '-webkit-center' }} className="no-bottom-padding">
              <Image size="large" src='./img/logo/celebrate.jpg' />
              </Segment>
            </Grid.Column>
            <Grid.Column largeScreen={6} textAlign="center">
              <Header size="huge" className="content-header">
                The remote-friendly company benefit
              </Header>
              <Grid
                stackable
                centered
                columns={2}
                verticalAlign='middle'
              >
                <Grid.Column>
                  <Segment basic textAlign="center" className="no-bottom-padding">
                    <Header size="big" style={{fontWeight: 'normal'}} icon>
                      <Icon name='like' style={{ fontSize: '35px' }} />
                      Organize charity actions around activities that add excitement and unite your teams
                    </Header>
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment basic textAlign="center" className="no-bottom-padding">
                  <Header size="big" style={{fontWeight: 'normal'}} icon>
                    <Icon name='gift' style={{ fontSize: '35px' }} />
                    Expand your benefits with a friendly competition and discounts at selected partners
                  </Header>
                  </Segment>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment
          basic
          textAlign="center"
          style={{
            background: 'rgba(4,71,110,.4)'
          }}
          id="easy-as-123"
        >
          <Header as='h1' inverted>Easy as 1, 2, 3</Header>
          <Container>
            <Grid
              verticalAlign="top"
              centered
              columns={3}
              stackable            
            >
              <Grid.Column textAlign="center">
                <Grid.Row>
                  <Statistic
                    value='1'
                    size='small'
                    inverted
                    className="reduced-padding"
                  />
                </Grid.Row>
                <Grid.Row>
                  <Header as='h3' inverted>Create a league</Header>
                  <p className="white-text">Configure the initial challenge for your company (no sign up required).</p>
                  <p className="white-text">Don't know what to choose? Don't worry, you can create more leagues later!</p>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Grid.Row>
                  <Statistic
                    value='2'
                    size='small'
                    inverted
                    className="reduced-padding"
                  />
                </Grid.Row>
                <Grid.Row>
                  <Header as='h3' inverted>Receive a Join Code</Header>
                  <p className="white-text">We'll reach out shortly to customize your league, and share a unique code so you can invite your colleagues</p>
                  <p className="white-text">We can help you craft the explanatory email for your employees</p>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Grid.Row>
                  <Statistic
                    value='3'
                    size='small'
                    inverted
                    className="reduced-padding"
                  />
                </Grid.Row>
                <Grid.Row>
                  <Header as='h3' inverted>Share and Play!</Header>
                  <p className="white-text">Users will be able to join as many leagues as they want, and even create more for dedicated teams!</p>
                </Grid.Row>
              </Grid.Column>
            </Grid>
          </Container>
          <Divider hidden />
          <Grid.Row style={{ textAlign: '-webkit-center' }}>
            <CreateLeagueModal context={Constants.NAV__CORPORATE_SECTION} label='Create League Now' />
          </Grid.Row>
        </Segment>
        <Segment
          basic
          textAlign="left"
          style={{
            padding: '2em 0em'
          }}
        >
          <Container>
            <Header as='h2' textAlign="center">Frequently Asked Questions</Header>
            <Divider />
            <Header as='h3'>How do I get started with Ballkie for my company?</Header>
            <p>
              You just need to create a league and invite your colleagues to join. You’ll be able to see the people joining the league on the league page, and the ranking will stay up-to-date automatically as games go by.
            </p>
            <Divider />
            <Header as='h3'>Can Ballkie be customized to match our company’s branding?</Header>
            <p>
              Yes! We will customize your league page, including your logo and up to two color customizations. If you want more customization, reach out to us!
            </p>
            <Divider />
            <Header as='h3'>Is there a minimum number of participants required to create a league?</Header>
            <p>
              No minimum is required, but we recommend having 5+ users so you can start enjoying Ballkie to its fullest.
            </p>
            <Divider />
            <Header as='h3'>What kind of support does Ballkie offer for setting up and managing leagues?</Header>
            <p>
              You can reach out to us through email, and we’ll be happy to answer any questions or concerns. We typically reply in less than one working day.
            </p>
            <Divider />
            <Header as='h3'>How can I integrate Ballkie into our existing benefits programme?</Header>
            <p>
              With Ballkie, you will be able to add some benefits as prizes, or make your charity programme more interactive.
            </p>
            <Divider />
            <Header as='h3'>What if I don't have a benefit programme?</Header>
            <p>
              Ballkie can help you set up a initial programme, thank to our exclusive disccounts in selected partners.
            </p>
            <Divider />
            <Header as='h3'>Is Ballkie accessible on both mobile and desktop devices?</Header>
            <p>
              Yes, we have a native app available for iOS and Android, as well as a web version.
            </p>
            <Divider />
            <Header as='h3'>Is Ballkie a gambling platform?</Header>
            <p>
              No, Ballkie promotes fun competition among friends, and our manifesto is strongly against any form of gambling.
            </p>
          </Container>
        </Segment>
      </>
    );
  };

  export default Corporate;
