import { Segment, Container, Grid, Header, List, Modal } from 'semantic-ui-react'
import Constants from "../../constants/constants";

const Footer = ({ matches }) => {
  return (
    <Segment
      vertical
      style={{ marginTop: '10em', bottom: '0px' }}
      className='main-background'
    >
      <Container>
        <Grid stackable className='no-margin' columns={2} relaxed='very' >
          <Grid.Row>
            <Grid.Column width={7} style={{paddingLeft: '1em'}}>
              <Header as='h4' inverted>
                Ballkie
              </Header>
              <p>
                Predict and compete with friends. <br />
                Customize your leagues and play for free
              </p>
            </Grid.Column>
            <Grid.Column width={9} textAlign='left'>
              <Header inverted as='h4' content='About' />
              <List link inverted>
                <Modal
                  size="mini"
                  trigger={
                    <List.Item as='a'>Ranking Criteria</List.Item>
                  }
                >
                  <Modal.Header>Ranking Criteria</Modal.Header>
                  <Modal.Content>
                    <p>
                      1. Points <br />
                      2. Ratio (points/predictions) <br />
                      3. xPoints (ascending)
                    </p>
                  </Modal.Content>
                </Modal>
                <Modal
                  size="mini"
                  trigger={
                    <List.Item as='a'>Manifesto</List.Item>
                  }
                >
                  <Modal.Header>Manifesto</Modal.Header>
                  <Modal.Content>
                    <p>
                      Sad as it is, gambling has been part of sports culture for decades, and goverments have never given it the importance it deserves:
                      while tobacco and alcohol were banned as official sponsors of teams and leagues, betting houses built an empire and continue
                      to sponsor most of the European teams. <br /><br />

                      Ballkie was born with the purpose of offering a healthy alternative to sport betting, while maintaining the fun aspects of it: 
                      competing with friends, bragging, and winning a few beers along the way. <br /><br />

                      If you or your loved ones suffer from gambling addiction, please read <a href='https://www.helpguide.org/articles/addictions/gambling-addiction-and-problem-gambling.html'>these recommendations</a> carefully and seek help. <br /><br />
                    </p>
                  </Modal.Content>
                </Modal>
                <Modal
                  size="mini"
                  trigger={
                    <List.Item as='a'>Contact Us</List.Item>
                  }
                >
                  <Modal.Header>Contact Us</Modal.Header>
                  <Modal.Content>
                    <p>
                      We are always trying to improve. If you see a bug or you have a crazy good idea, send us an email to ballkie.app@gmail.com
                    </p>
                  </Modal.Content>
                </Modal> 
                <Modal
                  size="mini"
                  trigger={
                    <List.Item as='a'>Credits</List.Item>
                  }
                >
                  <Modal.Header>Credits</Modal.Header>
                  <Modal.Content>
                    <p>                      
                      Established in 2022, Ballkie was created to offer an alternative way for fans to engage with sports, challenging them to put their knowledge to the test.<br /><br />

                      It was first founded by Edu, but quickly grow to a team of four, adding Adam, Dom, and Justin, whose sports predictions might make you question the laws of probability, but their passion is unmatched.<br /><br />

                      The photo in our main page is from <a href='https://unsplash.com/@whodunelson?utm_source=unsplash&utm_medium=referral'>Nelson Ndongala</a>, 
                      found on <a href='https://unsplash.com/images/sports/soccer?utm_source=unsplash&utm_medium=referral'>Unsplash</a>
                    </p>
                    <p>
                      The photo in our corporate section is from <a href='https://unsplash.com/@jovanvasiljevic?utm_source=unsplash&utm_medium=referral'>Jovan Vasiljević</a>, 
                      found on <a href='https://unsplash.com/images/sports/soccer?utm_source=unsplash&utm_medium=referral'>Unsplash</a>
                    </p>
                  </Modal.Content>
                </Modal>
                <Modal
                  trigger={
                    <List.Item as='a'>Gambling Adiction Support</List.Item>
                  }
                >
                  <Modal.Header>Gambling Adiction Support</Modal.Header>
                  <Modal.Content>
                    <p>
                      <b>Spain</b>: <a href='https://ludopatiaonline.com/asociaciones-de-ayuda-ludopatia/'>Gambling Support Associations</a> <br /><br />

                      Note: if you know a gambling Support Association, reach out to us and we'll list it here
                    </p>
                  </Modal.Content>
                </Modal>  
                <Modal
                  size="mini"
                  trigger={
                    <List.Item as='a'>Terms & Conditions</List.Item>
                  }
                >
                  <Modal.Header>Terms & Conditions</Modal.Header>
                  <Modal.Content>
                    <p>
                      If you want to know more about our Terms and Conditions or Privacy Policy, you can find them in the following links:<br /><br />
                      <a href={window.location.origin + Constants.NAV__PRIVACY_POLICY_URI} target="_blank">Privacy Policy</a> <br />
                      <a href={window.location.origin + Constants.NAV__TERMS_CONDITIONS_URI} target="_blank">Terms & Conditions</a>
                    </p>
                  </Modal.Content>
                </Modal>               
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
}

export default Footer;