import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Cookies, getCookieConsentValue, getLegacyCookieName } from "react-cookie-consent";
import { Button } from 'semantic-ui-react'

import Constants from "../../constants/constants";

const LoginButton = ( props ) => {
  const { loginWithRedirect } = useAuth0();
  
  const logIn = () => {
    // If the cookies weren't accepted, we save them as accepted
    if (!getCookieConsentValue(Constants.COOKIES__CONSENT)) {
      Cookies.set(
        Constants.COOKIES__CONSENT,
        true,
        {
          expires: Constants.COOKIES__CONSENT_EXPIRATION,
        }
      );

      // Fallback for older browsers where can not set SameSite=None,
      // SEE: https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
      Cookies.set(
        getLegacyCookieName(Constants.COOKIES__CONSENT),
        true,
        {
          expires: Constants.COOKIES__CONSENT_EXPIRATION,
        }
      );
    }
    loginWithRedirect();
  }

  return (
    <Button
      { ...props }
      onClick={() => logIn()}
    />
  );
};

export default LoginButton;