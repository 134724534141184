import React, {useState, useEffect} from 'react';

import { formatDistanceToNowStrict, isBefore, add } from "date-fns"
import Constants from "../../constants/constants";
import Util from "../../util";

import {
  Grid, Segment, Image, Label, Icon
} from 'semantic-ui-react'


const LeagueCard = ({ league, membersFormatter }) => {
  const [leagueIsOver, setLeagueIsOver] = useState(true)
  const [leagueIsNearlyOver, setLeagueIsNearlyOver] = useState(false)
  const [crestUris, setCrestUris] = useState([])
  const leagueId = league._id

  useEffect(() => {
    const now = new Date()
    const oneMonthFromNow = add(now, {
      months: 1,
    })
    
    setLeagueIsNearlyOver(isBefore(new Date(league.endDate), oneMonthFromNow))
    setLeagueIsOver(isBefore(new Date(league.endDate), now))

    if (league.competitionDetails) {
      const competitionUris = league.competitionDetails.reduce((result, competition) => [...result, competition.crestDarkURI], []);
      setCrestUris(competitionUris);
    }
  }, [league])

  const nth = (n) => {
    return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th"
  }
  const simplifyNumber = (n) => {
    return n < 1000 ?
      n :
      (n / 1000).toFixed(1).replace(/\.0$/, '') + 'k'
  }

  return (
    <Segment className='no-padding no-margin' style={{ cursor: 'pointer' }} >
      <Grid basic style={{ justifyContent: 'center' }} className='no-margin'>
        <Grid.Row style={{ alignItems: 'center' }}>
          {crestUris.length ?
            (
              <Grid.Column width={4} basic className='no-padding no-margin' style={{ justifyContent: 'center' }}>
                <Segment basic className='no-padding no-margin' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                {crestUris.map((uri) => (
                  <Image
                    style={{
                      width: crestUris.length < 3 ? '30px' : Math.round(70 / crestUris.length) + 'px',
                      resizeMode: 'contain',
                      padding: '2px'
                    }}
                    src={`./img/competitions/${uri}.png`}
                  />))
                }
                </Segment>
              </Grid.Column>
            ) :
            null
          }
          <Grid.Column width={8} basic className='no-padding no-margin' >
            {Util.renderLeagueDetailLink(league.name, league._id)}
          </Grid.Column>
          <Grid.Column width={4} basic className='no-margin' textAlign='right' style={{ padding: '0 0.5rem 0 0' }} >
            { league.dynasty?.logoURI && (
              <Grid.Row style={{ textAlign: '-webkit-right' }}>
                <Image
                    style={{
                      height: '30px',
                      resizeMode: 'contain',
                      paddingBottom: '2px'

                    }}
                    src={league.dynasty.logoURI}
                  />
              </Grid.Row>
            )}
            <Grid.Row>
              <Label
                size='tiny'
                color={league.isOver ? 'grey' : ''}
                basic={!league.isOver}
              >
                { league.isOver ?
                  (<Icon name='flag checkered' />) :
                  (<></>)
                }
                { (!league.isOver) ?
                  Util.renderRankDiff(league.rankDiff) :
                  null
                }
                { league.rank ?
                    (membersFormatter.format(league.rank)) :
                    (membersFormatter.format(league.members))
                }{'º'}
              </Label>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default LeagueCard;
