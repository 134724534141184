import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from 'react-router-dom';

// Components
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";

// Styles
import 'semantic-ui-css/semantic.min.css?version=11';
import "./styles/index.css";

ReactDOM.render(
  <BrowserRouter>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0__DOMAIN}
      clientId={process.env.REACT_APP_AUTH0__CLIENT_ID}
      redirectUri={window.location.origin + process.env.REACT_APP_AUTH0__REDIRECT_URI}
      audience={process.env.REACT_APP_AUTH0__AUDIENCE}
      scope={process.env.REACT_APP_AUTH0__SCOPE}
    >
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Auth0Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
