import { Link } from "react-router-dom";
import { Menu, Image, Icon, Divider, Message } from 'semantic-ui-react'

import React, { useState, useEffect } from "react";

import LogoutButton from "../Log In/LogOutButton";

import "../../styles/navigation.css";

import Constants from "../../constants/constants";

const Navigation = ({ matches }) => {
  const [activeItem, setActiveItem] = useState('');

  useEffect(() => {

    //On the first load, we check the URI
    setActiveItem('/' + window.location.pathname.replaceAll('/', ''));
  
  }, [window.location.pathname]);

  const hideNavigator = () => {
    if (
      activeItem === '/' ||
      activeItem === Constants.NAV__PRIVACY_POLICY_URI ||
      activeItem === Constants.NAV__TERMS_CONDITIONS_URI ||
      activeItem === Constants.NAV__CORPORATE ||
      activeItem.includes('redirect')
    ) {
      return true;
    }

    return false;
  }

  const handleClick = (event, data) => {
    setActiveItem(data.to);
  }

  return hideNavigator() ?
    (
      <></>
    )
    : (
      <>
        <Menu
          icon='labeled'
          size='tiny'
          style={{fontSize: '11px', verticalAlign: 'middle', marginBottom: '0px'}}
        >
          <Menu.Item>
            <Link
              to={Constants.NAV__GAMES_URI}
              onClick={handleClick}
            >
              <Image 
                src='./img/logo/logo_blue.png'
                height={40}
              />
            </Link>
          </Menu.Item>
          { activeItem !== Constants.NAV__UNSUBSCRIBE_URI ?
            (
              <>
                <Menu.Item
                  key={Constants.NAV__GAMES_SECTION}
                  as={Link}
                  to={Constants.NAV__GAMES_URI}
                  onClick={handleClick}
                  className={`${activeItem === Constants.NAV__GAMES_URI ? 'active' : ''}`}
                >
                  <Icon
                    name='calendar alternate outline'
                  />
                  UPCOMING
                </Menu.Item>
                <Menu.Item
                  as={Link}
                  key={Constants.NAV__RESULTS_SECTION}
                  to={Constants.NAV__RESULTS_URI}
                  onClick={handleClick}
                  className={`${activeItem === Constants.NAV__RESULTS_URI ? 'active' : ''}`}
                >
                    <Icon
                      name='flag checkered'
                    />
                    RESULTS
                </Menu.Item>
                <Menu.Item
                  as={Link}
                  key={Constants.NAV__LEAGUE_SECTION}
                  to={Constants.NAV__LEAGUES_URI}
                  onClick={handleClick}
                  className={`${activeItem.includes(Constants.NAV__LEAGUE_DETAIL_URI) ? 'active' : ''}`}
                >
                  <Icon
                    name='group'
                  />
                  LEAGUES
                </Menu.Item>
                <Menu.Menu position='right'>
                  <LogoutButton></LogoutButton>
                </Menu.Menu>
              </>
            ):
            (
              <></>
            )
          }
        </Menu>
        <Message
          attached='bottom'
          success
          icon='gift'
          size='small'
          content='For a more optimised experience and new features, consider downloading our app!'
        />
        <Divider hidden />
      </>
    );
}

export default Navigation;