import Constants from "../../../constants/constants";
import { useState } from "react";

import {
  Button, Modal, Icon, Segment, Divider, Grid, Loader, Input, GridColumn
} from 'semantic-ui-react'

import LeagueCenter from "../LeagueCenter";
import LeagueAPI from "../../../api/league";
import Util from "../../../util";


const JoinLeagueModal = ({ reloadLeagues }) => {
    const [isOpen, setOpen] = useState(false);
    const [isPasswordOpen, setPasswordOpen] = useState(false);
    const [password, setPassword] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [availableLeagues, setAvailableLeagues] = useState([]);
    const [selectedLeague, setSelectedLeague] = useState(null);

    const handleOpen = async () => {
      try {
          setOpen(true);
          setSearchLoading(true);

          // Initial search of public leagues
          const foundLeagues = await LeagueAPI.searchLeagues(search);

          setSearchLoading(false);

          if (foundLeagues.length) {
            setAvailableLeagues(foundLeagues);
          }

          setSearchLoading(false);
      } catch(error) {        
          // Stop Loading
          setSearchLoading(false);

          // Fire error
          Util.handleError(error, 'Failed to load leagues. Please reload');
      }
  }


    const handleSearch = async (event) => {
      event.preventDefault();
  
      try {

        setSearchLoading(true);
        setAvailableLeagues([]);
        setSelectedLeague(null);
        
        const foundLeagues = await LeagueAPI.searchLeagues(search);

        setSearchLoading(false);

        if (foundLeagues.length) {
          setAvailableLeagues(foundLeagues);
        }

      } catch(error) {        

        // Stop Loading
        setSearchLoading(false);

        // Fire error
        Util.handleError(error, 'Something went wrong. Please retry!');
      }

    };

    const handleJoin = async (event) => {
      event.preventDefault();

      const selectedLeagueObject = availableLeagues.filter(league => league._id === selectedLeague);

      if (!selectedLeagueObject.length) {
        Util.handleError(null, 'Please select a League to proceed');

        return;
      }

      // There's only one matching by Id, so we just remove the array envelope
      const league = selectedLeagueObject[0];
      
      if (league.type === Constants.LEAGUE_TYPE__PUBLIC) {
        // If the league is public, we can proceed with adding the user (no need for password)
        try {
          setSearchLoading(true);

          const response = await LeagueAPI.joinLeague(league._id);

          if (response.success) {
            // Close Pop up
            setSearchLoading(false);
            setOpen(false);

            // Show success message
            Util.successMessage('Game On!', 'You\'ve joined ' + league.name + ' league successfully. Good Luck!');

            // Reload Leagues
            await reloadLeagues();
          } else {
            setSearchLoading(false);

            Util.handleError(response.data, '');
          }

        } catch(error) {        

          // Stop Loading
          setSearchLoading(false);
  
          // Fire error
          Util.handleError(error, 'Something went wrong. Please retry!');
        }
      } else {
        // If the league is private, show second modal to request password
        setPassword('');
        setPasswordOpen(true);
      }

    };

    const handleJoinPrivateLeague = async (event) => {
      event.preventDefault();

      if (password === '') {
        Util.handleError(null, 'Please provide a password!');
      }

      try {
        setPasswordOpen(false);
        setSearchLoading(true);

        const selectedLeagueObject = availableLeagues.filter(league => league._id === selectedLeague);

        if (!selectedLeagueObject.length) {
          Util.handleError(null, 'Please select a League to proceed');
  
          return;
        }
  
        // There's only one matching by Id, so we just remove the array envelope
        const league = selectedLeagueObject[0];

        const response = await LeagueAPI.joinLeague(
          league._id,
          Util.hashString(password)
        );

        if (response.success) {
          // Close Pop up
          setSearchLoading(false);
          setOpen(false);

          // Show success message
          Util.successMessage('Game On!', 'You\'ve joined ' + league.name + ' league successfully. Good Luck!');

          // Reload Leagues
          await reloadLeagues();
        } else {
          setSearchLoading(false);
          setPasswordOpen(true);

          Util.handleError(response.data, '');
        }

      } catch(error) {        

        // Stop Loading
        setSearchLoading(false);

        // Fire error
        Util.handleError(error, 'Something went wrong. Please retry!');
      }

    };

    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && event.target.id === 'search-input') {
        handleSearch(event);
        return;
      }

      if (event.key === 'Enter' && event.target.id === 'password-input') {
        handleJoinPrivateLeague(event);
        return;
      }
    };

    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => handleOpen()}
        open={isOpen}
        trigger={
          <Button
            fluid
            color='blue'
            icon
            labelPosition='left'
            style={{marginBottom: '0.5em'}}
          >
            <Icon name='user plus' />
            Join Existing League
          </Button>
        }
        size='small'
      >
        <Modal.Header>Join a League</Modal.Header>
        <Modal.Content scrolling>
          <Segment
            basic
            loading={searchLoading}
          >
            <p>
              Find and join existing Leagues created by other users.<br /><br />
              You can look for public leagues by just hitting search.
            </p>
            <p>
              If you are looking for a Private League, make sure you ask the admin for the password!
            </p>
            <Divider clearing />
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Input
                    id='search-input'
                    fluid
                    icon='search'
                    iconPosition='left'
                    placeholder='Search a League...'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Button
                    fluid
                    icon
                    color='blue'
                    onClick={(event) => handleSearch(event)}
                  >
                    <Icon name='search' />
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider hidden />
            <LeagueCenter
              leagues={availableLeagues}
              context={Constants.LEAGUE_CONTEXT__JOIN}
              selectedLeague={selectedLeague}
              setSelectedLeague={setSelectedLeague}
              maxHeight={200}
            >
            </LeagueCenter>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            content='Join League'
            onClick={(event) => handleJoin(event)}
            color='blue'
          />
        </Modal.Actions>
        <Modal
          onClose={() => setPasswordOpen(false)}
          open={isPasswordOpen}
          size='mini'
        >
          <Modal.Header>Secret Handshake?</Modal.Header>
          <Modal.Content>
            <Segment basic>
              <p>The League you are trying to join is private, please provide the password!</p>
              <Divider hidden/>
              <Grid.Column textAlign="center" widescreen={4}>
                <Input
                  id='password-input'
                  size='small'
                  icon='lock'
                  iconPosition='left'
                  placeholder='The password is...'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </Grid.Column>
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setPasswordOpen(false)}>
              Cancel
            </Button>
            <Button
              content='Join League'
              onClick={(event) => handleJoinPrivateLeague(event)}
              color='blue'
            />
          </Modal.Actions>
        </Modal>
      </Modal>
    );
  };
  
  export default JoinLeagueModal;
  