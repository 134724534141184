const Loading = () => {

  return (
    <>
      <div className="main ui container">
        <div className="ui segment">
          <div className="ui active inverted dimmer">
              <div className="ui text loader">Loading</div>
          </div>
          <br/><br/><br/>
        </div>
      </div>
    </>
  );
};

export default Loading;
