import React from 'react';

import "../../styles/matchstatus.css";

import {
  Segment, Grid, Image, Card
} from 'semantic-ui-react'

const BetDistribution = () =>
{

  return (
    <Segment basic >
      <Grid
        container
        verticalAlign='middle'
        centered
        stackable
        columns={2}
      >
        <Grid.Column largeScreen={7}>
          <Card fluid>
            <Image src={'./img/howtoplay/step-leagues.png'} wrapped />
            <Card.Content>
              <Card.Header>Own the experience</Card.Header>
              <Card.Description>
                Join the worldwide competition in public/private leagues. <br />
                Combine the sports you love, however you want
              </Card.Description>
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column largeScreen={7}>
          <Card fluid>
            <Image src={'./img/howtoplay/step-bet.png'} wrapped />
            <Card.Content>
              <Card.Header>Predict seamlessly</Card.Header>
              <Card.Description>
                Check the upcoming matches of your leagues, and chose who you think will win with just one click
              </Card.Description>
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column largeScreen={7}>
          <Card fluid>
            <Image src={'./img/howtoplay/step-results.png'} wrapped />
            <Card.Content>
              <Card.Header>Win points</Card.Header>
              <Card.Description>
                Earn points with each correct prediction
              </Card.Description>
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column largeScreen={7}>
          <Card fluid>
            <Image src={'./img/howtoplay/step-social.png'} wrapped />
            <Card.Content>
              <Card.Header>Become the ultimate champion</Card.Header>
              <Card.Description>
                Win the leagues, or brag individually in head to head battles
              </Card.Description>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default BetDistribution;