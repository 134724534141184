import { useState, useEffect } from "react";
import { Accordion, Icon, Grid, Image, Button } from 'semantic-ui-react'

import "../../../styles/competitions.css";

import Util from "../../../util";

const CompetitionSelector = (
  { competitions, selectedCompetitions, setLeagueCompetitions, activeIndex, setActiveIndex, maxCompetitions }
) => {
    const [sports, setSports] = useState([]);

    useEffect(() => {
      
      const competitionsObject = competitions.reduce((sports, competition) => {
        const sport = competition.sport.name;
        if (!sports[sport]) {
            sports[sport] = [];
        }
        sports[sport].push(competition);
        return sports;
      }, {});

      setSports(competitionsObject);   
      
    }, [competitions]);

    const selectCompetition = (event, newSelected) => {
      event.preventDefault();

      // If the competition was already selected, remove it
      if (selectedCompetitions.includes(newSelected)) {
        setLeagueCompetitions(
          selectedCompetitions.filter(comp => comp !== newSelected)
        );

        return;
      }
      
      // Check if they've reached the max number of competitions
      if (maxCompetitions && selectedCompetitions.length === maxCompetitions) {
        Util.handleError(null, 'You have reached the max number of competitions per league. Please deselect one before adding more');
        return;
      }
      
      // Otherwhise, add it
      setLeagueCompetitions([...selectedCompetitions, newSelected]);
    }

    const printSport = (sport, i) => {
      const competitions = sports[sport];
      let sportIcon = ''
      
      if (competitions.length) {
        sportIcon = competitions[0].sport.icon;
      }

      return (
        <>
          <Accordion.Title
            active={activeIndex.includes(i)}
            index={i}
            onClick={ (event, titleProps) => setActiveIndex(
              activeIndex.includes(titleProps.index) ?
                activeIndex.filter(tab => tab !== titleProps.index)
                : [...activeIndex, titleProps.index]
            )}
          >
            <Icon name="dropdown" />
            { sportIcon !== '' ? (<Icon name={sportIcon} />) : (<></>) }
            {sport}
          </Accordion.Title>
          <Accordion.Content active={activeIndex.includes(i)}>
            <Grid stackable>
              <Grid.Row>
                <Button.Group fluid>
                  {
                    competitions && competitions.length > 0 ?
                      competitions.map((competition) => (
                        <Button 
                          className={`ui button competition ${competition.colors.main}`}
                          id={competition._id}
                          active={selectedCompetitions.includes(competition._id)}
                          onClick={ (event) => selectCompetition(event, competition._id)}
                        >
                          <Image
                            height={30}
                            src={`./img/competitions/${selectedCompetitions.includes(competition._id) ? competition.crestURI : competition.crestDarkURI }.png`}
                          />
                        </Button>
                      )):
                      (
                        <span>You don't have any matches left to bet on!</span>
                      )
                  }
                </Button.Group>
              </Grid.Row>
            </Grid>
          </Accordion.Content>
        </>
      );
    }

    return (
      <Accordion fluid styled>
        {competitions.length ?
          Object.keys(sports).map((sport, index) => 
            printSport(sport, index)
          ) :
          (
            printSport('')
          )
        }
      </Accordion>
    );
  };
  
  export default CompetitionSelector;
  