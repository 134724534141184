import "../../styles/ranking.css";
import Util from "../../util";
import Constants from "../../constants/constants";

import {
  GridColumn, Table, Popup, Icon, Flag, Modal
} from 'semantic-ui-react'


const Ranking = ({ mode, showTrends, users = {}, maxHeight, style, isOver, isRankingFromCurrentWeek }) => {

  if (Object.keys(users).length) {
    // Classification criteria
    if (mode === Constants.RANKING_MODE__GENERAL) {
      users.sort((a, b) => (a.rank - b.rank));
    } else {
      users.sort((a, b) => {
        if(a.points === b.points) {
          if (a.ratio === b.ratio) {
            return (a.xPoints - b.xPoints)
          }

          return (b.ratio - a.ratio)
        }

        return (b.points - a.points)
      })
    }
  }

  /**
   * Show Week Leader
   * @returns {void}
   */
  const showWeekLeader = (user) => {
    if (showTrends && !isOver && mode === Constants.RANKING_MODE__GENERAL) {
      if (isRankingFromCurrentWeek) {
        // The week is ongoing, show the previous week leader
        return user.previousWeekLeader;
      } else {
        // Otherwise, the ranking is outdated and we must show the next week leader
        return user.currentWeekLeader;
      }
    }

    // Trends disabled or league over
    return false;
  }

  return (
    <GridColumn
      style={{ maxHeight, overflowY: 'auto', fontSize: '12px', ...style }}
    >
      <Table
        unstackable
        striped
        singleLine
        style={{borderStyle: 'none' }}
      >
        <Table.Header>
          <Table.HeaderCell
            textAlign='center'
          >
            #
          </Table.HeaderCell>
          <Table.HeaderCell
          >
            Name
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign='right'
            className='reduced-padding'
          >
            Pts
          </Table.HeaderCell>
          <Table.HeaderCell
            collapsing
            textAlign='right'
            className='reduced-padding'
          >
            <Popup
                size="mini"
                content='Ratio calculates points per predictions'
                position="right center"
                trigger={
                    <Icon
                        name="question circle"
                        color="grey"
                    />
                }
            />
            Ratio
          </Table.HeaderCell>
          <Table.HeaderCell
            collapsing
            textAlign='right'
            className='reduced-padding'
          >
            <Modal
              size="mini"
              trigger={
                <Icon
                    name="question circle"
                    color="grey"
                />
              }
            >
              <Modal.Header>Expected Points (xPTS)</Modal.Header>
              <Modal.Content>
                <p>
                  xPTS is a metric designed to measure the probability of success of a bet. <br />
                  It takes into account the bets of other users in the same match to determine the most probable outcome. <br /><br />

                  If you have more xPts than Pts, it means you failed easy matches. <br />
                  If you have more Pts than xPts, GG. You are the expert. The master. The GOAT.<br /><br />

                  The greater the Points to xPoints ratio (more points with less xPoints), the better
                  
                </p>
              </Modal.Content>
            </Modal>               
            xPts
          </Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {users.length ?
            users.map((user, index) => (
              <Table.Row
                key={ user._id }
                active={ user.isCurrentUser }
                className='ranking'
              >
                <Table.Cell collapsing>
                  {showTrends && Util.renderRankDiff(user.rankDiff)}
                  <b>
                    { 
                      (index === 0 && isOver) ?
                       ('🏆') :
                       ((index + 1) + '.')
                    }
                  </b>
                </Table.Cell>
                <Table.Cell>
                  <Flag name={user.country} />
                  {
                    user.isCurrentUser ?
                      user.name :
                      Util.renderUserProfileLink(user.name, user._id)
                  }
                  { showWeekLeader(user) ?
                    (' 🔥') :
                    null
                  }
                  {
                    showTrends && user.showActivityWarning ?
                      (
                        <Popup
                          size="mini"
                          position="right center"
                          on='click'
                          trigger={
                            <Icon
                              name="warning sign"
                              className="only-left-margin"
                              color="yellow"
                            />
                          }
                        >
                          Pending upcoming matches!
                          <a
                            href="whatsapp://send?text=Hey, you didn't Ballkie!"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Icon name="whatsapp square" color='green' size="big" />
                          </a>
                        </Popup>
                      ) :
                      (<></>)
                  }
                </Table.Cell>
                <Table.Cell collapsing textAlign='right'>
                  { user.points }
                </Table.Cell>
                <Table.Cell collapsing textAlign='right'>
                  { user.ratio.toFixed(3) }
                </Table.Cell>
                <Table.Cell collapsing textAlign='right'>
                  { user.xPoints.toFixed(1) }
                </Table.Cell>
              </Table.Row>
            )) :
            (
              <Table.Row>
                <Table.Cell />
                <Table.Cell>Nobody came to the party</Table.Cell>
              </Table.Row>
            )
          }
        </Table.Body>
      </Table>
    </GridColumn>
  );
};

export default Ranking;
