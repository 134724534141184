import "../../styles/leagues.css";

import Constants from "../../constants/constants";
import Util from "../../util";
import LeagueCard from "./LeagueCard";

import {
  GridColumn, Table, Icon, Label, Segment, List
} from 'semantic-ui-react'


const LeagueCenter = ({ leagues, context, selectedLeague, setSelectedLeague, maxHeight }) => {



  leagues.sort((a, b) => {
    if (a.type === Constants.LEAGUE_TYPE__PRIVATE && b.type === Constants.LEAGUE_TYPE__PUBLIC) {
      return -1;
    } else if (a.type === Constants.LEAGUE_TYPE__PUBLIC && b.type === Constants.LEAGUE_TYPE__PRIVATE) {
      return 1;
    } else {
      return b.members - a.members;
    }
  });

  const handleRowClick = (league) => {
    if(setSelectedLeague) {
      setSelectedLeague(league);
    }
  };

  // Number of members format
  const membersFormatter = new Intl.NumberFormat('en-GB', {
    notation: 'compact', compactDisplay: 'short'
  });

  return (
    <GridColumn style={{ maxHeight, overflowY: 'auto',  maxWidth: '700px' }} >
      { context === Constants.LEAGUE_CONTEXT__DISPLAY ?
        (
          <Segment basic className="reduced-padding no-margin">
            <List relaxed>
              {leagues.length ?
                leagues.map((league) => (
                  <List.Item key={league._id} style={{ paddingBottom: '5px' }}>
                    <LeagueCard league={league} membersFormatter={membersFormatter} />
                  </List.Item>
                )) :
                (<></>)
              }
            </List>
          </Segment>
        ) :
        (
          <Table
            unstackable
            selectable
          >
            <Table.Body>
              {leagues.length ?
                leagues.map((league) => (
                  <Table.Row
                    key={ league._id }
                    active={ league._id === selectedLeague }
                    onClick={() => handleRowClick(league._id)}
                    className='league'
                  >
                    <Table.Cell
                      collapsing
                      className='no-right-padding'
                    >{
                      league.type === Constants.LEAGUE_TYPE__PUBLIC ?
                        (<Icon name='users' className="no-margin"/>) :
                        (<Icon name='lock' className="no-margin"/>)
                      }
                    </Table.Cell>
                    <Table.Cell
                      className='no-right-padding'
                    >
                      {
                        context === Constants.LEAGUE_CONTEXT__DISPLAY ?
                          Util.renderLeagueDetailLink(league.name, league._id)
                          : league.name
                      }
                    </Table.Cell>
                    {
                      context === Constants.LEAGUE_CONTEXT__DISPLAY ?
                        (
                          <Table.Cell
                            collapsing
                            className='only-right-padding'
                            textAlign='right'
                            colSpan={ league.isOver ? 2 : 1 }
                          >
                            <Label
                              size='tiny'
                              color={league.isOver ? 'grey' : ''}
                              basic={!league.isOver}

                            >
                              { league.isOver ?
                                (<Icon name='flag checkered' />) :
                                (<></>)
                              }
                              { (!league.isOver) ?
                                Util.renderRankDiff(league.rankDiff) :
                                null
                              }
                              { league.rank ?
                                  (membersFormatter.format(league.rank)) :
                                  (membersFormatter.format(league.members))
                              }{'º'}
                            </Label>
                          </Table.Cell>
                        ) :
                        (<></>)
                    }
                    {
                        (!league.isOver) ?
                          (
                            <Table.Cell
                              collapsing
                              className='only-right-padding'
                              textAlign='right'
                            >
                              <Label size='mini' color='blue'>
                                <Icon name='user' />
                                  {membersFormatter.format(league.members)}
                              </Label>
                            </Table.Cell>
                          ) :
                          (<></>)
                    }
                  </Table.Row>
                )) :
                (
                  <Table.Row>
                    <Table.Cell>No leagues</Table.Cell>
                  </Table.Row>
                )
              }
            </Table.Body>
          </Table>
        )
      }
    </GridColumn>
  );
};

export default LeagueCenter;
