
import React, { useEffect, useState } from "react";
import { Segment } from 'semantic-ui-react'

import Util from "../util";
import Loading from "../components/Main/Loading";

// API
import UserAPI from "../api/user";

const Unsubscribe = () => {
  const [isLoading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    async function unsubscribeUser(id) {
      try {

        const response = await UserAPI.unsubscribe(id);

        // Update success and stop loading
        setSuccess(response.success);
        setLoading(false);
      } catch(error) {
        // Stop Loading
        setLoading(false);

        // Set success false
        setSuccess(false);
  
        // Fire errorx
        Util.handleError(error, 'Something went wrong, please reload');
      }
      
    }

    // Reset loader
    setLoading(true);

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    if (id) {
      // Get league info
      unsubscribeUser(id);
    } else {
      // If there's no user id, we can't unsubscribe them
      Util.handleError(null, 'No user provided, please contact support if the error persists');

      setSuccess(false);
      setLoading(false);
    }

  }, []);

  return isLoading ?
    (
      <Loading/>
    ) :
    (
      <>
        <Segment basic>
          { success ?
            (
              <p>
                ✅ You've been correctly unsubscribed!
              </p>
            ) :
            (
              <p>
                Something went wrong, please reload
              </p>
            )
          }
        </Segment>
      </>
    )
};

export default Unsubscribe;
