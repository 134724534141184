import React, { useEffect, useState, createRef } from "react";
import moment from 'moment';
import {
  Header, GridColumn, Segment, Dropdown, Icon, Grid, Checkbox
} from 'semantic-ui-react'
import { Link } from 'react-router-dom';

import "../../styles/calendar.css";

import Constants from "../../constants/constants";
import Match1X2 from "./Match/Match1X2";
import MatchPodium from "./Match/MatchPodium";
import MobileShare from "./Aux/mobileShare";

const matchStatusFilterOptions = [
  {
    key: 'All',
    text: 'all matches',
    value: false
  },
  {
    key: 'Pending',
    text: 'pending matches',
    value: true
  },
];

const allCompetitionsOption = {
  key: 'All',
  text: 'all competitions',
  value: false
};

const Calendar = ({ matches, scope }) => {
  const [calendarView, setCalendarView] = useState([]);
  const [betStatusFilter, setBetStatusFilter] = useState(false);
  const [competitionsAvailable, setCompetitionsAvailable] = useState([]);
  const [competitionFilter, setCompetitionFilter] = useState(false);
  const [showBetDistribution, setShowBetDistribution] = useState(true);
  let shareRef = [];


  const createCalendar = () => {
    const localTimeMatches = matches.map((match) => ({...match, localTime: (new Date(match.time))}));  
    let orderedMatches;

    // In the upcoming matches, we order from closest to further
    if (isBetScope()) {
      orderedMatches = localTimeMatches.sort((a, b) => new Date(a.localTime) - new Date(b.localTime));
    } else {
      // In the results page, we order the other way around
      orderedMatches = localTimeMatches.sort((a, b) => new Date(b.localTime) - new Date(a.localTime));
    }

    // this gives an object with dates as keys
    const auxCalendar = orderedMatches.reduce((groups, match) => {
      const day = moment(match.localTime).format("DD MMM");

      if (!groups[day]) {
        groups[day] = [];
      }
      groups[day].push(match);
      return groups;
    }, {});

    return auxCalendar;
  }

  /**
   * Returns if the bet is possible in the current scope or not
   * @returns {Boolean} - true if betting is possible, false if not
   */
  const isBetScope = () => {
    return scope === Constants.NAV__GAMES_SECTION;
  }

  useEffect(() => {
    // Sets the calendar view
    const calendarObject = createCalendar();
    setCalendarView(calendarObject);

    // Get different competitions available
    if (matches.length) {
      const uniqueCompetitions = matches.reduce((uniqueCompetitions, match) => {
        const competition = match.competition;
        if (!uniqueCompetitions.some(comp => comp.key === competition._id)) {
          uniqueCompetitions.push(
            {
              key: competition._id,
              text: competition.name,
              value: competition._id,
              image: {
                src: `./img/competitions/${competition.crestDarkURI}.png`,
                size:'mini',
                spaced: 'right',
                className: 'competition-filter-logo'
              },
            }
          );
        }
        return uniqueCompetitions;
      }, []);

      // Adding default option
      setCompetitionsAvailable([
        allCompetitionsOption,
        ...uniqueCompetitions
      ]);

    } else {
      setCompetitionsAvailable([ allCompetitionsOption ]);
    }

  }, [matches]); // Empty array tells UseEffect to run only once

  const printDayMatches = (day) => {
    const dayMatches = calendarView[day];
    shareRef[day] = createRef(null);

    return (
      <div ref={shareRef[day]}>
        <Header
          dividing
          textAlign='left'
          className="day-div"
        >
          <Grid>
          <Grid.Row>
            <Grid.Column width={10}>{day}</Grid.Column>
            <Grid.Column textAlign='right' width={6}>
              { scope !== Constants.NAV__PROFILE_SECTION ?
                (
                  <MobileShare
                    sourceRef={shareRef[day]}
                  />
                ):
                (<></>)
              }
            </Grid.Column>
          </Grid.Row>
          </Grid>
        </Header>
        {
          dayMatches && dayMatches.length > 0 ?
            (  
              dayMatches.map((m) => 
                (!m.type || m.type === Constants.MATCH_TYPE__1X2) ?
                  (
                    <Match1X2
                      match={m}
                      scope={scope}
                      filterBets={betStatusFilter}
                      filterCompetition={competitionFilter}
                      showBetDistribution={showBetDistribution}
                      isBetScope={isBetScope()}
                    />
                  ) :
                  (
                    <MatchPodium
                      match={m}
                      scope={scope}
                      filterBets={betStatusFilter}
                      filterCompetition={competitionFilter}
                      isBetScope={isBetScope()}
                    />
                  )
                )
            ):
            (
              !isBetScope() ?
                (
                  <p>
                    No predictions were made recently.<br /><br />
                    You can <Link to={Constants.NAV__LEAGUES_URI}>join or create a league</Link> and start playing
                  </p>
                ) :
                (
                  <p>
                    This is very quiet right now.<br /><br />
                    You can <Link to={Constants.NAV__LEAGUES_URI}>join or create a league</Link> and start playing
                  </p>
                )
            )
        }
      </div>
    );
  }

  const filters = () => {
    return isBetScope() ?
      (
        <>
          <Segment
            basic
            className='no-padding'
            style={{lineHeight: 1.6}}
          >
            <Icon name='filter' />
              Showing {' '}
              <Dropdown
                inline
                options={matchStatusFilterOptions}
                defaultValue={false}
                value={betStatusFilter}
                onChange={(event, target) => setBetStatusFilter(target.value)}
              />
              {' '} from {' '}
              <Dropdown
                inline
                options={competitionsAvailable}
                defaultValue={false}
                value={competitionFilter}
                onChange={(event, target) => setCompetitionFilter(target.value)}
              />
           <br /><br />
           <Checkbox
            checked={showBetDistribution}
            fitted
            toggle
            label={showBetDistribution ?
              'Hide Ballkie bet distribution' :
              'Show Ballkie bet distribution'
            }
            onClick={() => setShowBetDistribution(!showBetDistribution)}
          />
          </Segment>
        </>
      ) :
      (<></>)
  }

  return (
    <>
      {filters()}
        <GridColumn>
          {matches.length ?
            Object.keys(calendarView).map((day) =>
              printDayMatches(day)
            ) :
            (
              printDayMatches('')
            )
          }
        </GridColumn>
    </>
  );
};

export default Calendar;
