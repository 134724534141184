import React, {useState, useEffect} from 'react';
import {
  Segment, Grid, GridColumn, Dropdown, Statistic
} from 'semantic-ui-react'
import moment from 'moment';


// styles
import "../../../styles/global.css";
import "../../../styles/match.css";
import "../../../styles/competitions.css";

// Components
import MatchStatus from "../MatchStatus"
import Constants from "../../../constants/constants";

// API
import BetAPI from "../../../api/bet";
import Util from "../../../util";


const MatchPodium = ({ match, filterBets, filterCompetition, isBetScope }) => {
  const [hidden, setHidden] = useState(false);
  const [currentBet, setBet] = useState({});
  const [disabledButtons, setDisabledButtons] = useState(false);
  const [loadingFirstPlace, setLoadingFirstPlace] = useState(false);
  const [loadingSecondPlace, setLoadingSecondPlace] = useState(false);
  const [loadingThirdPlace, setLoadingThirdPlace] = useState(false);
  const [sameDriverAlert, setSameDriverAlert] = useState(false);
  const { competition, participants, bet } = match;

  // Format participant into the dropdown options
  const participantsOptions = participants.map((participant) => {
    return {
      key: participant.name,
      text: participant.shortName,
      value: participant._id,
      image: { 
        src: `./img/teams/${participant.crestURI ? participant.crestURI : Constants.MATCH_DEFAULT_TEAM__LOGO}.png`,
        size: 'huge'
      }
    }
  });

  useEffect(() => {
    if (bet) {
      setBet(bet.podiumBet);
      updateSameDriverBetFlag(bet.podiumBet);
    }

    if (!isBetScope) {
      setDisabledButtons(true);
    }
  }, [bet]);

  useEffect(() => {
    let hidden = false;

    // If the filter per pending bets is enabled, we hide the match
    if (filterBets && currentBet) {
      hidden = true
    } else {
      // If there's no bet or no filter on bets, we filter per competition
      if (filterCompetition) {
        if (filterCompetition !== match.competition._id) {
          hidden = true;
        }
      }
    }

    setHidden(hidden);
  }, [filterBets, filterCompetition])

  /**
   * Set the flag of a loading position for this match
   * @param {String} position - set the indicated position as loading
   * @param {boolean} loading - status of the load
   * @returns {void}
   */
  const setLoadingPosition = (position, loading) => {
    if (position === Constants.MATCH_PODIUM__FIRSTPLACE) {
      setLoadingFirstPlace(loading);
    } else if (position === Constants.MATCH_PODIUM__SECONDPLACE) {
      setLoadingSecondPlace(loading);
    } else {
      setLoadingThirdPlace(loading);
    }
  }

  /**
   * Set the flag of a duplicated drive bet
   * @param {Object} betObject - bet saved
   */
  const updateSameDriverBetFlag = (betPodium) => {
    if (
      ( betPodium.firstPlace?.id && betPodium.firstPlace?.id === betPodium.secondPlace?.id ) ||
      ( betPodium.firstPlace?.id && betPodium.firstPlace?.id === betPodium.thirdPlace?.id ) ||
      ( betPodium.secondPlace?.id && betPodium.secondPlace?.id === betPodium.thirdPlace?.id )
    ) {
      setSameDriverAlert(true);
    } else {
      setSameDriverAlert(false);
    }
  }

  /**
   * Update a bet object with a new bet
   * @param {Object} initialBet - initial bet object to update
   * @param {String} position - position that has been bet
   * @param {String} bet - bet of the given position
   * @returns {Object} - a new bet object
   */
  const updateBetObject = (initialBet, position, bet) => {
    const updatedBet = initialBet;
  
    if (position === Constants.MATCH_PODIUM__FIRSTPLACE) {
      if (bet) {
        updatedBet.firstPlace = { id: bet };
      } else {
        delete updatedBet.firstPlace;
      }
    } else if (position === Constants.MATCH_PODIUM__SECONDPLACE) {
      if (bet) {
        updatedBet.secondPlace = { id: bet };
      } else {
        delete updatedBet.secondPlace;
      }
    } else {
      if (bet) {
        updatedBet.thirdPlace = { id: bet };
      } else {
        delete updatedBet.thirdPlace;
      }
    }

    return updatedBet;
  }

  /**
   * Sets the new bet based on the reply from the server
   * @param {String} bet - new bet
   * @returns {void}
   */
  const setNewBet = (bet) => {
    if (bet === Constants.MATCH_BET__EXPIRED) {
      Util.handleError(null, 'The match is now in progress, we cant save your prediction');
      return;
    }

    setBet(bet);
    updateSameDriverBetFlag(bet);
  }

  /**
   * Handler called when placing a bet
   * @param {String} position - position of the podium
   * @param {String} teamId - id of the team selected
   * @returns {void}
   */
  const handleBet = async (position, teamId) => {
    try {
      // Disable buttons of the match to prevent re-bets while saving
      setDisabledButtons(true);

      // Set loading until value is saved
      setLoadingPosition(position, true);

      const now = moment().toDate();
      const matchTime = moment(match.time).toDate();

      if(now < matchTime) {

        const newBet = updateBetObject(currentBet, position, teamId);

        // API call to save bet
        const reply = await BetAPI.upsertPodiumBet(match, newBet);

        setDisabledButtons(false);

        if (!reply.success) {
          Util.handleError(null, 'We couldn\'t save your bet, please retry');
        } else {
          setNewBet(reply.data);
        }

      } else {
        Util.handleError(null, 'The match is now in progress, we cant save your bet');
      }

      //Stop Loading
      setLoadingPosition(position, false);

    } catch(error) {        
      // Stop Loading
      setLoadingPosition(position, false);

      // Fire error
      Util.handleError(error, 'We couldn\'t save your bet, please retry');
  }
    
  };

  return (
    hidden ?
      (<></>) :
      (
        <>
          <MatchStatus
            status={
              (match.status === Constants.MATCH_STATUS__SCHEDULED && !isBetScope) ?
                Constants.MATCH_STATUS__PLAYING :
                match.status
            }
            time={match.localTime}
            sport={competition?.sport?.icon}
            ko={match.ko}
            bet={currentBet}
            result={match.result}
            points={bet?.points}
            xPoints={null}
            sameDriverAlert={sameDriverAlert}
            includesET={match.includesET}
            bonusPoints={match.bonusPoints}
          />
          <Segment
            attached
            raised
            style={{ border: '0.5px solid' }}
          >
            <Grid>
              <GridColumn
                width={2}
                textAlign='center'
                className={`match-competition active ${competition.colors.main}`}
              >
                <img
                  alt='competition-logo'
                  height={30}
                  src={`./img/competitions/${competition.crestURI}.png`}
                  style={{align:'center'}}
                />
              </GridColumn>
              <GridColumn
                width={14}
              >
                <Grid
                  textAlign='center'
                  className={`reduced-padding active ${competition.colors.main}`}
                >
                  <h4>
                    {match.eventName}
                  </h4>
                </Grid>
                {/* First Place */}
                <Grid
                  className='match podium border'
                >
                  <GridColumn
                    verticalAlign='middle'
                    textAlign='right'
                    width={3}
                  >
                    <Statistic
                      value='#1'
                      size='mini'
                    />
                  </GridColumn>
                  <GridColumn
                    width={match.status === Constants.MATCH_STATUS__FINISHED ? 9 : 13}
                  >
                    <Dropdown
                      placeholder='Select a pilot'
                      options={participantsOptions}
                      fluid
                      selection
                      search
                      clearable
                      disabled={disabledButtons}
                      loading={loadingFirstPlace}
                      value={currentBet?.firstPlace?.id}
                      onChange={(event, target) => handleBet(Constants.MATCH_PODIUM__FIRSTPLACE, target.value)}
                    />
                  </GridColumn>
                  { match.status === Constants.MATCH_STATUS__FINISHED ? 
                    (
                      <GridColumn
                        width={4}
                        verticalAlign='middle'
                        className='no-padding'
                      >
                        {Util.renderPodiumMatchPoints(bet?.podiumBet?.firstPlace?.points)}
                      </GridColumn>
                    ):
                    (<></>)
                  }
                </Grid>

                {/* Second Place */}
                <Grid
                  className='match podium border'
                >
                  <GridColumn
                    verticalAlign='middle'
                    textAlign='right'
                    width={3}
                  >
                    <Statistic
                      value='#2'
                      size='mini'
                    />
                  </GridColumn>
                  <GridColumn
                    width={match.status === Constants.MATCH_STATUS__FINISHED ? 9 : 13}
                  >
                    <Dropdown
                      placeholder='Select a pilot'
                      options={participantsOptions}
                      fluid
                      selection
                      search
                      clearable
                      disabled={disabledButtons}
                      loading={loadingSecondPlace}
                      value={currentBet?.secondPlace?.id}
                      onChange={(event, target) => handleBet(Constants.MATCH_PODIUM__SECONDPLACE, target.value)}
                    />
                  </GridColumn>
                  { match.status === Constants.MATCH_STATUS__FINISHED ? 
                    (
                      <GridColumn
                        width={4}
                        verticalAlign='middle'
                        className='no-padding'
                      >
                        {Util.renderPodiumMatchPoints(bet?.podiumBet?.secondPlace?.points)}
                      </GridColumn>
                    ):
                    (<></>)
                  }
                </Grid>

                {/* Third Place */}
                <Grid>
                  <GridColumn
                    verticalAlign='middle'
                    textAlign='right'
                    width={3}
                  >
                    <Statistic
                      value='#3'
                      size='mini'
                    />
                  </GridColumn>
                  <GridColumn
                    width={match.status === Constants.MATCH_STATUS__FINISHED ? 9 : 13}
                  >
                    <Dropdown
                      placeholder='Select a pilot'
                      options={participantsOptions}
                      fluid
                      selection
                      search
                      clearable
                      disabled={disabledButtons}
                      loading={loadingThirdPlace}
                      value={currentBet?.thirdPlace?.id}
                      onChange={(event, target) => handleBet(Constants.MATCH_PODIUM__THIRDPLACE, target.value)}
                    />
                  </GridColumn>
                  { match.status === Constants.MATCH_STATUS__FINISHED ? 
                    (
                      <GridColumn
                        width={4}
                        verticalAlign='middle'
                        className='no-padding'
                      >
                        {Util.renderPodiumMatchPoints(bet?.podiumBet?.thirdPlace?.points)}
                      </GridColumn>
                    ):
                    (<></>)
                  }
                </Grid>
              </GridColumn>
            </Grid>
          </Segment>
        </>
      )
  );
};

export default MatchPodium;
