import { useEffect, useState } from "react";
import { Grid, Segment, Header, Menu, Container } from 'semantic-ui-react'

import "../styles/leagues.css";

// API
import LeagueAPI from "../api/league";

// Components
import Util from "../util";
import LeagueCenter from "../components/Main/LeagueCenter";
import Loading from "../components/Main/Loading";
import CreateLeagueModal from '../components/Main/Aux/createLeagueModal';
import JoinLeagueModal from '../components/Main/Aux/joinLeagueModal';
import Constants from "../constants/constants";


const Leagues = () => {
  const [leagues, setLeagues] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(0);

  useEffect(() => {
    async function getLeagues() {
      try {
        const loadLeagues = await LeagueAPI.getUserLeagues();
        setLeagues(loadLeagues);
      } catch(error) {
        // Stop Loading
        setLoading(false);
  
        // Fire errorx
        Util.handleError(error, 'Failed to load leagues, please reload');
      }
      
    }

    async function joinLeague(joinToken) {
      try {
        const response = await LeagueAPI.acceptInviteLeague(joinToken);
  
        if (response.success) {
          // Show success message
          Util.successMessage('Game On!', 'You\'ve joined ' + response.data.leagueName + ' league successfully. Good Luck!');
  
          // Reload Leagues
          await reloadLeagues();
        } else {
          setLoading(false);
  
          Util.handleError(response.data, '');
        }
  
      } catch(error) {        
  
        // Stop Loading
        setLoading(false);
  
        // Fire error
        Util.handleError(error, 'Something went wrong. Please retry!');
      }
    }

    // Reset loader
    document.title = 'Leagues';
    setLoading(true);
    setFirstLoad(0);

    // Check if the user is trying to join a league
    const urlParams = new URLSearchParams(window.location.search);
    const joinToken = urlParams.get('join');

    // If so, we first join the league, then load the rest
    if (joinToken) {
      joinLeague(joinToken);
      getLeagues();
    } else {
      // Get leagues
      getLeagues();
    }
  
  }, []);

  useEffect(() => {
    if (firstLoad) {
      setLoading(false); // Skip the UseState load
    } else {
      setFirstLoad(1);
    }
  }, [leagues]);

  const reloadLeagues = async () => {
    try {

      setLoading(true);
      const loadLeagues = await LeagueAPI.getUserLeagues();
      setLeagues(loadLeagues);

    } catch(error) {
      // Stop Loading
      setLoading(false);

      // Fire errorx
      Util.handleError(error, 'Failed to load leagues, please reload');
    }

  }

  return isLoading ?
    (
      <Loading/>
    ) :
    (
      <div className="main ui container">
        <Grid stackable >
          <Grid.Column width={4}>
            <Segment raised>
              <Header textAlign="center">
                League Management
              </Header>
              <Menu fluid vertical className="no-borders">
                <CreateLeagueModal context={Constants.NAV__LEAGUE_SECTION} reloadLeagues={reloadLeagues}></CreateLeagueModal>
                <JoinLeagueModal reloadLeagues={reloadLeagues}></JoinLeagueModal> 
              </Menu>
            </Segment>
          </Grid.Column>
          <Grid.Column width={12}>
            <Container>
              <Header>
                Your Leagues
              </Header>
              <LeagueCenter
                leagues={leagues}
                context={Constants.LEAGUE_CONTEXT__DISPLAY}
                maxHeight={400}
              />
            </Container>
          </Grid.Column>
        </Grid>
      </div>
    )
    

};

export default Leagues;
