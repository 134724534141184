import { useEffect, useState, createRef } from "react";
import { Container, Header, Segment, GridColumn, Tab, Statistic, Divider } from 'semantic-ui-react'
import ApexChart from 'react-apexcharts';
import moment from "moment";

// API
import MatchAPI from "../api/match";
import UserAPI from "../api/user";

// Components
import Util from "../util";
import Constants from "../constants/constants";
import Loading from "../components/Main/Loading";
import Calendar from "../components/Main/Calendar";
import MobileShare from "../components/Main/Aux/mobileShare";

const Profile = () => {
  const [user, setUser] = useState({});
  const [userId, setUserId] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(0);
  const [error, setError] = useState(false);
  const [betTabLoading, setBetTabLoading] = useState(false);
  const [matches, setMatches] = useState([]);
  const [h2hTabLoading, setH2HTabLoading] = useState(true);
  const [h2hData, seth2hData] = useState({});

  //H2H Metrics with format h2hMetric = { own: XX, versus: YY }
  const [h2hPoints, setH2HPoints] = useState({});
  const [h2hRatio, setH2HRatio] = useState({});
  const [h2hEvolution, setH2HEvolution] = useState({});

  const shareRef = createRef(null);

  useEffect(() => {
    async function getUser(id) {
      try {
        // Get basic info of the user
        const response = await UserAPI.getUserInfo(id);

        if(response.success) {
          setUser(response.data);

          const h2hResponse = await UserAPI.getH2H(id);

          seth2hData(h2hResponse.data);
          setH2HTabLoading(false);
        } else {
          // If we don't find the user, return error
          setError(true);
        }
      } catch(error) {
        // Stop Loading
        setError(true);
        setLoading(false);
        setH2HTabLoading(false);
      }
      
    }

    // Reset loader
    setLoading(true);
    setFirstLoad(0);

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    if (id) {
      // Get league info
      setUserId(id);
      getUser(id);
    }

  }, []);

  useEffect(() => {
    if (firstLoad) {
      setLoading(false); // Skip the UseState load
      document.title = user?.name;
    } else {
      setFirstLoad(1);
    }
  }, [user]);

  useEffect(() => {
    // Prep H2H Metrics
    // Prep Totals
    const ownTotalPoints = h2hData?.user1?.reduce((acc, month) => acc + month.totalPoints, 0);
    const ownTotalBets = h2hData?.user1?.reduce((acc, month) => acc + month.totalBets, 0);

    const versusTotalPoints = h2hData?.user2?.reduce((acc, month) => acc + month.totalPoints, 0);
    const versusTotalBets = h2hData?.user2?.reduce((acc, month) => acc + month.totalBets, 0);

    setH2HPoints({
      own: ownTotalPoints,
      versus: versusTotalPoints
    });

    setH2HRatio({
      own: (ownTotalPoints/ownTotalBets).toFixed(2),
      versus: (versusTotalPoints/versusTotalBets).toFixed(2)
    });

    // Prep monthly evolution
    setH2HEvolution({
      own: h2hData?.user1?.
        sort((a, b) => a.yearMonth.localeCompare(b.yearMonth)),
      versus: h2hData?.user2?.
        sort((a, b) => a.yearMonth.localeCompare(b.yearMonth))
    });

    setH2HTabLoading(false);

  }, [h2hData]);

  const refreshActiveTab = async (tabIndex) => {
    if (tabIndex === 0) {
      //H2H tab will be loaded by default, so no need to refresh
    } else {
      // If the recent bets are already loaded, we just show them
      if (!matches.length) {
        // Load Recent Bets
        setBetTabLoading(true);

        try {
          const loadMatches = await MatchAPI.getMatchesUser(userId);
          setMatches(loadMatches);
          setBetTabLoading(false);
        } catch(error) {
          // Stop Loading
          setBetTabLoading(false);
    
          // Fire errorx
          Util.handleError(error, 'Failed to load recent bets, please reload');
        }
      }
    }
  }

  // Points chart
  const pointEvolutionChart = {
    series: [{
      name: 'you',
      data: h2hEvolution?.own?.map((month) => month.totalPoints),
    }, {
      name: user?.name,
      data: h2hEvolution?.versus?.map((month) => month.totalPoints),
    },],
    options: {
      chart: {
        type: 'bar',
        stacked: true,
        stackType: '100%',
        toolbar: { show: false }, // Removes the option to download graph
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '70%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'middle',
        formatter: function(value, opts) {
          return opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex];
        }
      },
      stroke: {
        show: false,
      },
      xaxis: {
        categories: h2hEvolution?.own?.map((month) => moment(month.yearMonth, 'YYYY-MM').format('MMM')),
        labels: {
          show: false
        },
        axisTicks: {
          show: false,
        },
      },
      title: {
        text: 'Points per month',
        align: 'center'
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top'
      },
      annotations: {
        xaxis: [{
          x: 50,
          x2: null,
          borderColor: '#ffffff',
        }]
      },
      colors: [
        function color({ value, seriesIndex }) {
          if (seriesIndex === 0) {
            return Constants.H2H__OWN_COLOR;
          }

          return Constants.H2H__RIVAL_COLOR;
        },
      ],
    },
  };

  // Ratio chart
  const ratioEvolutionChart = {
    series: [{
      name: 'you',
      data: h2hEvolution?.own?.map((month) => (month.totalPoints / month.totalBets).toFixed(2)),
    }, {
      name: user?.name,
      data: h2hEvolution?.versus?.map((month) => (month.totalPoints / month.totalBets).toFixed(2)),
    },],
    options: {
      chart: {
        type: 'bar',
        stacked: true,
        stackType: '100%',
        toolbar: { show: false }, // Removes the option to download graph
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '70%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'middle',
        formatter: function(value, opts) {
          return opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex];
        }
      },
      stroke: {
        show: false,
      },
      xaxis: {
        categories: h2hEvolution?.own?.map((month) => moment(month.yearMonth, 'YYYY-MM').format('MMM')),
        labels: {
          show: false
        },
        axisTicks: {
          show: false,
        },
      },
      title: {
        text: 'Ratio per month',
        align: 'center'
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top'
      },
      annotations: {
        xaxis: [{
          x: 50,
          x2: null,
          borderColor: '#ffffff',
        }]
      },
      colors: [
        function color({ value, seriesIndex }) {
          if (seriesIndex === 0) {
            return Constants.H2H__OWN_COLOR;
          }

          return Constants.H2H__RIVAL_COLOR;
        },
      ],
    },
  };

  const panes = [
    { 
      menuItem: 'H2H',
      render: () => (
        <Tab.Pane
          key='h2h'
          loading={h2hTabLoading}
        >
          <Segment basic>
            <MobileShare
              sourceRef={shareRef}
              className={"only-bottom-margin"}
            />
            <div ref={shareRef}>
              <Header>
                <span style={{color: Constants.H2H__OWN_COLOR}}>
                  you
                </span>
                {' vs '}
                <span style={{color: Constants.H2H__RIVAL_COLOR}}>
                  {user?.name}
                </span>
              </Header>
              <p>
                Showing results since you both joined and only in competitions in common
              </p>
              <Divider horizontal>TOTALS</Divider>
              <Segment
                basic
                textAlign='center'
                className='reduced-padding no-margin'>
                <Statistic.Group size='tiny' widths={2}>
                  <Statistic>
                    <Statistic.Value className='main-text'>{h2hPoints?.own}</Statistic.Value>
                    <Statistic.Label className='main-text'>points</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value className='secondary-text'>{h2hPoints?.versus}</Statistic.Value>
                    <Statistic.Label className='secondary-text'>points</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
                <Divider hidden />
                <Statistic.Group size='tiny' widths={2}>
                  <Statistic>
                    <Statistic.Value className='main-text'>{h2hRatio?.own}</Statistic.Value>
                    <Statistic.Label className='main-text'>ratio</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value className='secondary-text'>{h2hRatio?.versus}</Statistic.Value>
                    <Statistic.Label className='secondary-text'>ratio</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              </Segment>
              <Divider horizontal>EVOLUTION</Divider>
              <ApexChart
                series={pointEvolutionChart?.series}
                options={pointEvolutionChart?.options}
                type="bar"
                width="100%"
                height={ pointEvolutionChart?.series[0]?.data?.length * Constants.H2H__GRAPH_SCALE_FACTOR }
              />
              <ApexChart
                series={ratioEvolutionChart?.series}
                options={ratioEvolutionChart?.options}
                type="bar"
                width="100%"
                height={ ratioEvolutionChart?.series[0]?.data?.length * Constants.H2H__GRAPH_SCALE_FACTOR }
              />
            </div>
          </Segment>
        </Tab.Pane>
      )
    },
    { 
      menuItem: 'Recent Bets',
      render: () => (
        <Tab.Pane
          key='bets'
          loading={betTabLoading}>
          <Segment basic>
            <p>
              You are seeing {user?.name}'s most recent bets
            </p>
          </Segment>
          <Calendar
            matches={matches}
            scope={Constants.NAV__PROFILE_SECTION}
          />
        </Tab.Pane>
      ),
    }
  ]

  return isLoading ?
    (
      <Loading/>
    ) :
    (
      error ?
        (
          <>
            <Container style={{textAlign: '-webkit-center'}}>
              <Segment
                inverted
                color='red'
              >
                <Header as='h3' style={{overflow: 'hidden'}}>
                  User not found
                </Header>
              </Segment>
            </Container>
          </>
        ) :
        (
          <>
            <Container style={{textAlign: '-webkit-center'}}>
              <Segment
                inverted
                className='main-background'
              >
                <Header as='h3' style={{overflow: 'hidden'}}>
                  {user?.name.toUpperCase()}
                </Header>
              </Segment>
              <GridColumn>
                <Tab
                  menu={{ secondary: true, pointing: true, compact: true }}
                  panes={panes}
                  onTabChange={(event, target) => refreshActiveTab(target.activeIndex)}
                  renderActiveOnly
                />
              </GridColumn>
            </Container>
          </>
        )
    )
    

};

export default Profile;
