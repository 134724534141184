import LogInManagement from "../components/Log In/LogInManagement";

const Welcome = () => {

  return (
      <>
        <LogInManagement />
      </>
    )
    

};

export default Welcome;
