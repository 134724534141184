import { useEffect, useState } from "react";
import { Button, Divider, Segment, Header } from 'semantic-ui-react'

// API
import MatchAPI from "../api/match";

// Components
import Util from "../util";
import Calendar from "../components/Main/Calendar";
import Loading from "../components/Main/Loading";
import Constants from "../constants/constants";
import UserAPI from "../api/user";


const Home = () => {
  const [matches, setMatches] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(0);
  const [emailPermission, setEmailPermission] = useState(false);
  const [permissionLoading, setPermissionLoading] = useState(false);

  useEffect(() => {
    async function getMatches() {

      try {
        const isEmailSubscribed = await UserAPI.isSubscribed();
        setEmailPermission(isEmailSubscribed);

        const loadMatches = await MatchAPI.getUpcomingMatches();
        setMatches(loadMatches);
      } catch(error) {
        // Stop Loading
        setLoading(false);
  
        // Fire error
        Util.handleError(error, 'Failed to load matches. Please reload');
      }
    }

    // Reset loader
    document.title = 'Home';
    setLoading(true);
    setFirstLoad(0);

    // Get matches
    getMatches();
    
  }, []);

  useEffect(() => {
    if (firstLoad) {
      setLoading(false); // Skip the UseState load
    } else {
      setFirstLoad(1);
    }
  }, [matches]);

  const setSubscribed = async () => {
    setPermissionLoading(true);
    
    try {
      // Subscribe the user to the reminder emails
      const response = await UserAPI.subscribe();

      if (response.success) {
        //Show success message
        Util.successMessage('All set!', 'You\'ll receive notifications when there are new matches upcoming');

        // Update vars in the front end
        setEmailPermission(true);
      } else {
        Util.handleError(null, 'Something went wrong. Please try again');
      }
    } catch(error) {
      // Fire error
      Util.handleError(error, 'Something went wrong. Please retry');
    }

    setPermissionLoading(false);
  }

  return isLoading ?
    (
      <Loading/>
    ) :
    (
      <>
        <div className="main ui container">
          <Segment basic>
            <Header>Show time!</Header>
            <p>
              Upcoming matches of the competitions you have registered for can be viewed here.<br /><br />
              Select your pick by clicking on the name of the team, or the X if you think it will be a tie
            </p>
          </Segment>
          <Calendar
            matches={matches}
            scope={Constants.NAV__GAMES_SECTION}
          />
          <Divider clearing />
          <Divider hidden />
          <p>
            🎉 That's it for now! 🎉<br /><br />
            If you are subscribed to our alert system, we'll 
            send you an email if there are pending matches in less than 24h
          </p>
          { emailPermission ?
            (
              <></>
            ):
            (
              <>
                <Button
                  primary
                  loading={permissionLoading}
                  onClick={() => setSubscribed()}
                >
                  Notify me when I have more upcoming matches to play
                </Button>
              </>
            )
          }
        </div>
      </>
    )
    

};

export default Home;
