import React, {useState, useEffect, useSyncExternalStore} from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import {
  Segment, Table, Input, Button, Header, Dropdown
} from 'semantic-ui-react';

import Constants from "../../../constants/constants";

// API
import AdminAPI from "../../../api/admin";
import Util from "../../../util";

const AdminMatch = ({ match, filterFinished, filterCompetition }) => {
  const [hidden, setHidden] = useState(false);
  const [localScore, setLocalScore] = useState(null);
  const [awayScore, setAwayScore] = useState(null);
  const [matchStatus, setMatchStatus] = useState(match?.status);
  const [loading, setLoading] = useState(false);

  const { result } = match;

  // Define the Match Status available
  const matchStatusOptions = [
    {
      key: Constants.MATCH_STATUS__SCHEDULED,
      text: Constants.MATCH_STATUS__SCHEDULED.toUpperCase(),
      value: Constants.MATCH_STATUS__SCHEDULED,
    },
    {
      key: Constants.MATCH_STATUS__PLAYING,
      text: Constants.MATCH_STATUS__PLAYING.toUpperCase(),
      value: Constants.MATCH_STATUS__PLAYING,
    },
    {
      key: Constants.MATCH_STATUS__FINISHED,
      text: Constants.MATCH_STATUS__FINISHED.toUpperCase(),
      value: Constants.MATCH_STATUS__FINISHED,
    },
    {
      key: Constants.MATCH_STATUS__POSTPONED,
      text: Constants.MATCH_STATUS__POSTPONED.toUpperCase(),
      value: Constants.MATCH_STATUS__POSTPONED,
    },
    {
      key: Constants.MATCH_STATUS__CANCELLED,
      text: Constants.MATCH_STATUS__CANCELLED.toUpperCase(),
      value: Constants.MATCH_STATUS__CANCELLED,
    },
    {
      key: Constants.MATCH_STATUS__IGNORED,
      text: Constants.MATCH_STATUS__IGNORED.toUpperCase(),
      value: Constants.MATCH_STATUS__IGNORED,
    },
  ]

  useEffect(() => {
    if (result) {
      setLocalScore(result.home);
      setAwayScore(result.away);
    }
  }, [result]);

  useEffect(() => {
    let hidden = false;

    // If the filter per pending matches is enabled, we hide the match
    if (filterFinished && match.status === Constants.MATCH_STATUS__FINISHED) {
      hidden = true
    } else {
      // If there's no result saved or no filter on it, we filter per competition
      if (filterCompetition) {
        if (filterCompetition !== match.competition._id) {
          hidden = true;
        }
      }
    }

    setHidden(hidden);
  }, [filterFinished, filterCompetition])

  /**
   * Handler called when updating the result of a match
   * @returns {void}
   */
  const handleSave = async () => {
    try {
      let update = '';
      
      // Build the update message we are going to show
      if (matchStatus === Constants.MATCH_STATUS__FINISHED) {
        update = `Updating the score to: ${match.homeTeam.name} ${localScore} - ${match.awayTeam.name} ${awayScore}`;
      } else {
        update = `Updating the status of ${match.homeTeam.name} - ${match.awayTeam.name} from ${match.status} to ${matchStatus}`;
      }

      Swal.fire({
        title: 'Are you sure?',
        text: update,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        confirmButtonColor: '#439775',
        cancelButtonColor: 'lightgrey',
      }).then(async (result) => {
        // If the user confirms, we save in the Database
        if (result.isConfirmed) {
          setLoading(true);
          let errorFlag = false;
          let errorMessage = '';

          // Error check
          if (
            matchStatus === Constants.MATCH_STATUS__FINISHED && (
              !Number.isInteger(localScore) ||
              !Number.isInteger(awayScore)
            )
          ) {
            errorMessage = 'When closing a match, you need to provide the score';
            errorFlag = true;
          }

          if (!errorFlag) {
          
            try {
              const result = await AdminAPI.update1X2Result(
                match._id,
                match.competition.apiId,
                matchStatus,
                localScore,
                awayScore
              );

              if (result.success) {
                Swal.fire('Saved!', '', 'success');
                setLoading(false);
              } else {
                Util.handleError(result.data);
              }
            } catch (error) {
              Util.handleError(error);
              setLoading(false);
            }
          } else {
            Util.handleError(null, errorMessage);
            setLoading(false);
          }
        }
      })
    } catch(error) {        
      // Fire error
      Util.handleError(error, 'We couldn\'t save your bet, please retry');
  }
    
  };

  return (
    hidden ?
      (<></>) :
      (
        <>
          <Header
            attached='top' 
            textAlign='center'
            className='match-status no-padding'
            style={{ fontSize: '11px' }}
          >
            {'[' + match.apiId + ']'} <br/>
            {moment(match.time).format("DD MMM YYYY HH:mm")}
            {'  [' + match.status.toUpperCase() + '] → '}
            <Dropdown
              inline
              options={matchStatusOptions}
              value={matchStatus}
              onChange={(event, target) => setMatchStatus(target.value)}
            />
          </Header>
          <Segment
            className='no-padding'
            style={{ fontSize: '11px' }}
          >
            <Table
              celled
            >
              <Table.Body>
                <Table.Row
                  positive={ match.status === Constants.MATCH_STATUS__FINISHED }
                >
                  <Table.Cell width={4}>{match.homeTeam.name}</Table.Cell>
                  <Table.Cell width={3}>
                    <Input
                      type='number'
                      fluid
                      value={localScore}
                      onChange={event => setLocalScore(event.target.valueAsNumber)}
                    />
                  </Table.Cell>
                  <Table.Cell width={4}>{match.awayTeam.name}</Table.Cell>
                  <Table.Cell width={3}>
                    <Input
                      type='number'
                      fluid
                      value={awayScore}
                      onChange={event => setAwayScore(event.target.valueAsNumber)}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      fluid
                      primary
                      icon='save'
                      onClick={() => handleSave()}
                      loading={loading}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
        </>
      )
  );
};

export default AdminMatch;
