import { useEffect, useState } from "react";

// Components
import { Container, Header, Segment, Menu, Divider, Image, Grid, Icon, Button } from 'semantic-ui-react'
import CookieConsent from "react-cookie-consent";

import LoginButton from "../components/Log In/LogInButton";
import HowToPlay from "../components/Showcase/HowToPlay";
import Constants from "../constants/constants";
import Util from "../util";
import "../styles/main.css";

const Main = () => {
  const [accessFromJoin, setAccessFromJoin] = useState(false);
  const APPLE_STORE__URL = 'https://apps.apple.com/app/apple-store/id6477320391?pt=122577278&ct=web_redirect&mt=8';
  const ANDROID_STORE__URL = 'https://play.google.com/store/apps/details?id=com.ballkie';

  useEffect(() => {

    // Checks if the user was trying to access a specific resource right away
    const urlParams = new URLSearchParams(window.location.search);
    const redirectURI = urlParams.get('redirect');

    if (redirectURI) {
      // We save the URL the user was trying to access to redirect after login
      const redirect = decodeURI(redirectURI);
      localStorage.setItem(Constants.SESSION__REDIRECT_URL, redirect);

      // Sets if the access is coming from a Join League link
      setAccessFromJoin(redirect.includes('/leagues?join'));
    }
    
  }, []);

  /**
   * Updates the cookie consent if they were accepted (by default they are not enabled)
   * @returns {void}
   */
  const grantGACookieConsent = () => {
    window.gtag('consent', 'update',
    {
      'ad_storage': 'denied', // Not in use
      'analytics_storage': 'granted'
    });

    window.fbq('consent', 'grant');
  }

  const handleAppStoreClick = () => {
    window.location.href=APPLE_STORE__URL;
  }

  const handleGooglePlayClick = () => {
    window.location.href=ANDROID_STORE__URL;
  }

  return (
    accessFromJoin ?
    (
      <>
        <Segment
          textAlign='center'
          className="secondary-blue-background"
          vertical
        >
          <Container>
            <Segment basic style={{ textAlign: '-webkit-center' }} className="no-bottom-padding">
              <Image 
                src='./img/logo/logo_blue.png'
                height={80}
              />
            </Segment>
          </Container>
          <Divider hidden/>
          <Segment basic style={{ textAlign: '-webkit-center' }} className="no-bottom-padding">
            <Image size="small" src='./img/logo/players.png' />
            <Header
              as='h1'
              content='Welcome!'
              inverted
              style={{
                marginBottom: 0,
              }}
            />
            <p>You are one step away from joining the league and getting the competition started</p>
          </Segment>
          <Divider hidden/>
        </Segment>
        <Segment
          textAlign='center'
          vertical
          basic
          style={{textAlign: '-webkit-center'}}
        >
          <Header as='h2' content='How to join the fun?' />
          <Segment basic compact className="no-bottom-padding very-side-padded" >
            <Header as='h5' style={{fontWeight: 'normal'}} textAlign="left">
              <Icon name='user circle' style={{ fontSize: '30px' }} className="main-text" />
              <Header.Content>Create your account or log in using the buttons below</Header.Content>
            </Header>
            <Header as='h5' style={{fontWeight: 'normal'}} textAlign="left">
              <Icon name='check circle' style={{ fontSize: '30px' }} className="main-text" />
              <Header.Content>You'll be automatically added to your league</Header.Content>
            </Header>
            <Header as='h5' style={{fontWeight: 'normal'}} textAlign="left">
              <Icon name='gamepad' style={{ fontSize: '30px' }} className="main-text" />
              <Header.Content>Pick who you think will win each match and score points</Header.Content>
            </Header>
            <Header as='h5' style={{fontWeight: 'normal'}} textAlign="left">
              <Icon name='mobile' style={{ fontSize: '30px' }} className="main-text" />
              <Header.Content>We recommend using the app for an enhanced experience</Header.Content>
            </Header>
          </Segment>
          <Divider hidden/>
          <LoginButton content='Log In' size='medium' className='main-background' />
          <LoginButton content='Create Account' size='medium' className={'green-background'} />
          <Divider hidden/>
        </Segment>
        <Segment
          textAlign='center'
          vertical
          basic
          style={{textAlign: '-webkit-center'}}
          className="main-background"
        >
          <Divider hidden/>
          <Header as='h2' content='How does Ballkie work?' inverted/>
          <HowToPlay />
        </Segment>
        <Segment
          textAlign='center'
          vertical
          basic
          style={{textAlign: '-webkit-center'}}
        >
          <LoginButton content='Join Now' size='big' className={'green-background'} />
        </Segment>
      </>
    ) :
    (
      <>
        <Segment
          textAlign='center'
          style={{
            padding: '1em 0em',
            backgroundImage: 'url(./img/logo/main-back.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.3)',
            }}
          vertical
        >
          <CookieConsent
            location='bottom'
            visible='byCookieValue'
            cookieName={ Constants.COOKIES__CONSENT }
            expires={ Constants.COOKIES__CONSENT_EXPIRATION }
            containerClasses='cookies-banner'
            flipButtons
            //Accept info
            hideOnAccept
            buttonText='Accept'
            buttonClasses='main-background'
            onAccept={grantGACookieConsent}
            //Decline info
            enableDeclineButton
            declineButtonText='Only Essentials'
            declineButtonClasses='reverse-main-background'
          >
            Ballkie uses essential cookies to provide our services, and Google Analytics and Meta to analyze, and improve them.<br/><br/>
            Select Accept to consent or Only Essentials to decline non-essential cookies for this use.
            If you log in, essential cookies will be automatically applied.
          </CookieConsent>
          <Container>
            <Menu
              size='large'
              pointing
              className='no-margin no-border transparent-background'
            >
              <Menu.Item>
                  <Image
                    src='./img/logo/logo_white.png'
                    height={70}
                    verticalAlign='middle'
                  />
                  <span className='reduced-padding'>
                    <Header as='h1' inverted>Ballkie</Header>
                  </span>
                </Menu.Item>
              <Menu.Menu position='right'>
                <Menu.Item>
                  { !Util.isMobileOrTableUser() ?
                    (<LoginButton content='Log In' inverted></LoginButton>) :
                    (<></>)
                  }
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          </Container>
          <Divider hidden/>
          <Container text>
            <Header
              as='h1'
              content='Win or whine'
              inverted
              style={{
                fontWeight: 'normal',
                marginBottom: 0,
              }}
            />
            <Header
              as='h3'
              content='Prove you are the best, and brag to your friends'
              inverted
              className='reduced-margin'
            />
            <Divider hidden />
            { !Util.isMobileOrTableUser() ?
              (
                <LoginButton content='Get Started' icon='right arrow' labelPosition='right' size='big' className='main-background'></LoginButton>
              ) :
              (<></>)
            }
            <Segment basic className='no-margin no-side-padding'>
              <Grid
                verticalAlign='middle'
                centered
                columns={2}
                stretched
              >
                <Grid.Column className='no-padding'>
                  <Button basic className='no-side-padding'
                    onClick={handleAppStoreClick}
                  >
                    <Segment basic className='no-padding no-margin' textAlign={!Util.isMobileOrTableUser() ? 'right' : 'center'}>
                      <Image src='./img/logo/download-apple-store.png' height={50} style={{ display:'inline'}} />
                    </Segment>
                  </Button>
                </Grid.Column>
                <Grid.Column className='no-padding'>
                  <Button basic className='no-side-padding'
                    onClick={handleGooglePlayClick}
                  >
                    <Segment basic className='no-padding no-margin' textAlign={!Util.isMobileOrTableUser() ? 'left' : 'center'}>
                      <Image src='./img/logo/download-google-play.png' height={50} bordered style={{ borderColor: 'white', display: 'inline' }} />
                    </Segment>
                  </Button>
                </Grid.Column>
              </Grid>
            </Segment>
          </Container>
        </Segment>
        <Divider hidden />
        <HowToPlay />
        <Divider />
        <Segment
          basic
          textAlign='center'
        >
          <Grid stackable columns={1}>
            <Grid.Column>
              <LoginButton content='Log in web version' size='big' className={'main-background'} />
            </Grid.Column>
          </Grid>
        </Segment>
      </>
    )
  );
};

export default Main;
