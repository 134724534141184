import Constants from "../constants/constants"

const ErrorMessages = {
    
    // League Creation
    ERR_MSG__CREATE_LEAGUE__NAME_EMPTY: 'Please enter a League Name',
    ERR_MSG__CREATE_LEAGUE__NAME_INVALID_LENGTH: 'League Name has to be between ' + Constants.LEAGUE__MIN_LEAGUE_NAME + ' and ' + Constants.LEAGUE__MAX_LEAGUE_NAME + ' chars',
    ERR_MSG__CREATE_LEAGUE__NAME_INVALID_CHARS: 'League Name can only contain: A-Z, 0-9, "-", "_"',

    ERR_MSG__CREATE_LEAGUE__PSW_EMPTY: 'In a Private League, a password is mandatory',
    ERR_MSG__CREATE_LEAGUE__PSW_INVALID_CHARS: 'Password can only contain: A-Z, 0-9, "-", "_", "*", and "!"',

    ERR_MSG__CREATE_LEAGUE__TARGET_EMPTY:'Please specify the target of your league',

    ERR_MSG__CREATE_LEAGUE__COMPETITIONS_INVALID_SIZE: 'You need to select between 1 and ' + Constants.LEAGUE__MAX_COMPETITIONS + ' competitions',

    ERR_MSG__CREATE_LEAGUE__START_DATE_EMPTY: 'Please select when you want to start your league',
    ERR_MSG__CREATE_LEAGUE__START_DATE_PAST: 'League must start today or after',
    ERR_MSG__CREATE_LEAGUE__START_DATE_AFTER_COMPETITIONS: 'Competitions finishes before the league starts',

    ERR_MSG__CREATE_LEAGUE__LEAD_EMAIL_EMPTY: 'Please provide a contact email',
  
    // User Creation
    ERR_MSG__CREATE_USER__NAME_EMPTY: 'Please enter a username',
    ERR_MSG__CREATE_USER__NAME_INVALID_LENGTH: 'Username has to be between ' + Constants.USER__MIN_LENGTH_NAME + ' and ' + Constants.USER__MAX_LENGTH_NAME + ' chars',
    ERR_MSG__CREATE_USER__NAME_INVALID_CHARS: 'Username can only contain: A-Z, 0-9, "_"',

    ERR_MSG__CREATE_USER__COUNTRY_EMPTY: 'Please enter a country',
  };
  
  export default ErrorMessages;
  